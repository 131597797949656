.wgt-navbar {
  background-color: #1daeec;
  border-bottom: 5px solid #aaaaaa;
  color: var(--wgtWhite) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .navbar-toggler-icon {
    color: var(--wgtWhite) !important;
  }
  .navbar-brand {
    flex-direction: row;
    flex: 1;
    .image-logo {
      display: inline;
      img {
        height: 40px;
      }
    }
  }
  a {
    color: var(--wgtWhite) !important;
    &.highlight {
      font-weight: bolder;
    }
  }

  button {
    box-shadow: none !important;
    -webkit-font-smoothing: unset !important;
    border: none !important;
    background: none !important;
    color: var(--wgtWhite) !important;
    background-image: none !important;
  }

  .navbar-collapse {
    padding-top:2px;

    .firm-dropdown-menu {
      padding-right: 0;
    }
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      transition: 0.2s;
      height: 190px;
      justify-content: space-around;
      align-items: flex-start;
      padding-left: 12px;

      .wgt-navbar button,
      .user-dropdown-menu,
      .firm-dropdown-menu,
      .navbar-firm-menu {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}

.model-modal-gray {
  p {
    font-size: 14px !important;
    line-height: 1.25 !important;
    margin-bottom: 0 !important;
    color: var(--wgtTextColor);
    &.font-small {
      font-size: 12px !important;
    }
  }

  .modal-content {
    border: none;

    button {
      height: 36px;
      line-height: 1 !important;
    }

    .modal-header {
      background-color: #e5e5e5;
      border-bottom: 0;

      .modal-title {
        font-weight: bold !important;
        color: var(--wgtTextColor) !important;
        font-family: 'noto_sansregular' !important;
        font-size: 20px !important;
        line-height: 1 !important;
        padding-top: 0.25rem;
      }
    }

    .modal-footer {
      border-top-width: 2px;
      .label-password {
        display: inline-block;
        font-size: 11px;
        text-align: right;
        margin: 0 5px 0 0 !important;
      }
    }
  }
}

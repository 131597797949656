@import '../../../../../../../../assets/scss/scrollbar';

.scroll {
  @include scrollbar(rgb(255, 255, 255));
  .scrolling-wrapper {
    overflow-x: auto;
  }

  .card {
    border-radius: 0 !important;
  }

  .card-block {
    border: none;
    position: relative;
  }

  .card-img-top {
    max-height: 98px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    max-width: 125px;
  }

  .remove-media-button {
    background-color: #c0c0c0;
    position: absolute;
    padding: 1px;
    border-radius: 10px;
    right: 5%;
    top: 5%;
  }

  .thumbnail-item-a {
    cursor: pointer;
    margin: 0 10px;
    width: 130px;
    position: relative;

    &.active {
      border: 2px solid var(--wgtPrimary);
    }
    &.disabled {
      opacity: 0.3;
    }

    img {
      display: block;
      max-width: 125px;
      max-height: 98px;
      width: auto;
      height: auto;
      margin: auto;
    }
  }
}

.mineral-thumbnail-items-holder {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;

  .thumbnail-item-a {
    margin: 0 10px;

    width: 130px;
    display: inline-block;
    position: relative;

    &.active {
      border: 2px solid var(--wgtPrimary);
    }
    &.disabled {
      opacity: 0.3;
    }

    img {
      display: block;
      max-width: 125px;
      max-height: 98px;
      width: auto;
      height: auto;
      margin: auto;
    }

    .remove-media-button {
      background-color: #c0c0c0;
      position: absolute;
      padding: 1px;
      border-radius: 10px;
      right: 5%;
      top: 5%;
    }
  }
}

.Toastify__toast-container {
  > div {
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25) !important;
    border: solid 1px rgba(55, 60, 62, 0.2) !important;
  }
  .Toastify__toast--error {
    background-color: var(--wgtRed) !important;
  }

  .Toastify__toast--success {
    background-color: var(--primaryButton) !important;
  }

  .Toastify__close-button {
    display: none;
  }
}

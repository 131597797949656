.media-center-holder {
  background-color: #5f5e5e;
  border-radius: 0.25rem;
  overflow: hidden;

  .media-center-tabs {
    background-color: #202020;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    span {
      padding: 7px 40px;
      color: #fff;
      font-size: 12px !important;
      margin: 0;

      &[aria-selected='true'] {
        background-color: var(--wgtPrimary);
      }
    }
  }

  .media-center-content {
    flex: 1;
    height: 100%;
    display: flex;

    .media-center-content-holder {
      .media-center-content-image {
        flex: 1;
        align-items: center;
        display: flex;
        justify-content: center;
        height: 400px;
        width: 400px;
        background-repeat: no-repeat;
        background-position: center center;

        .layout-content-pages-images {
          background-color: #fff;
          width: 700px;
          display: flex;
          padding: 20px;
          flex-wrap: wrap;

          > div {
            width: 220px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 150px;
              height: auto;
            }
            span {
              text-align: left;
              width: 100%;
              padding-left: 30px;
            }
          }
        }
      }

      .content-side {
        width: 15%;
      }
    }

    .inclusion-tag-item {
      background-color: #fff;
      width: 150px;
      margin-top: 8px;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.isDragging {
        background-color: var(--wgtPrimary);
        color: #fff;
      }
    }
    .inclusion-pointer {
      display: flex;
      background-color: #f00;
      border-radius: 15px;
      height: 15px;
      width: 15px;
      position: absolute;

      &.loading {
        background-color: rgba(137, 138, 84, 0.363);
      }

      .inclusion-pointer-content {
        display: none;

        align-items: center;
        justify-content: space-between;
        background-color: #fff;

        height: 30px;
        border: 1px solid var(--wgtGray);
        border-radius: 5px;
        span {
          margin: 0 10px;
          white-space: nowrap;
        }
        button {
          margin-left: 15px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: transparent;
        .inclusion-pointer-content {
          display: flex;
          z-index: 1;
        }
      }
    }
    .page-layout-header {
      margin-bottom: 10px;
      margin-top: -50px;
      color: #fff;

      span {
        font-size: 12px !important;

        &:first-child {
          margin-right: 50px;
        }
      }
    }

    .layout-content-pages {
      margin-right: 20px;

      button {
        background-color: #fff;
        padding: 0;
        border-radius: 10px;
        overflow: hidden;

        .position {
          background-color: var(--wgtGray);
          padding: 7px 20px;
          color: #fff;
        }
        .page-name {
          display: flex;
          height: 35px;
          align-items: center;
          margin-left: 10px;

          div {
            height: 25px;
            width: 25px;
            background-size: cover;
            background-color: #fff;
            margin-right: 10px;
            border-radius: 20px;
          }
        }

        &.active {
          .position {
            background-color: var(--wgtPrimary);
          }
        }
      }
    }
  }
}

.dropzone {
  cursor: pointer;
  min-height: 160px;
  border: 1px dashed #9b9c9c;
  background: white;
  padding: 20px 20px;
  display: flex;
  background: none;
  h2 {
    color: #9b9c9c !important;
    font-style: italic;
    font-size: 20px;
  }

  .dropzone-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    span {
      margin: 15px 0;
      color: #9b9c9c !important;
    }
  }
}
.dropzone-files {
  .file-list-item {
    span {
      color: #fff !important;
    }
  }
}

.file-list-item {
  font-size: 13px;
  margin: 10px 0;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d2d3d3;
  span {
    color: var(--wgtPrimary);
  }
}

.media-center-background-selector {
  margin-top: -55px;
  margin-bottom: 40px;
  padding: 0 20px;
  color: #c0c0c0;
  font-size: 12px;
  display: flex;
  width: 300px;
  position: absolute;

  .media-center-background-box {
    height: 20px;
    cursor: pointer;
    width: 20px;
    margin-left: 10px;
    border: 1px solid #c0c0c0;
    border-radius: 2px;
  }
  .media-center-background-options {
    display: flex;
    align-items: center;
    position: relative;
    top: 25px;
    left: -40px;

    .media-center-background-option {
      height: 20px;
      width: 20px;
      border: 1px solid #c0c0c0;
      cursor: pointer;
    }
  }
}

.media-center-bottom {
  background-color: rgb(20, 20, 20);
  padding: 10px 0;
  display: flex;
  .nav-button {
    cursor: pointer;
    width: 30px;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
  }
  .thumbnails-holder {
    flex: 1;
    flex-direction: row;
    display: flex;
    .add-media-button {
      cursor: pointer;
      height: 100px;
      min-width: 100px;
      border: 1px solid #c0c0c0;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #fff;
      margin-right: 10px;
    }
  }
}

.media-center-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  .media-center-content-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      height: 400px;
    }
  }

  &.open {
    min-height: 455px;
    justify-content: flex-end;
  }
}

.button-expand-media-center {
  border: 1px solid #c0c0c0;
  font-size: 12px;
  padding: 7px;
  border-radius: 5px;
  color: #c0c0c0;
  cursor: pointer;
}

.zoom-options {
  border: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  margin-top: -100px;
  width: 60px;
  margin-bottom: 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.4s;

  &.open {
    opacity: 1;
  }

  div {
    color: #c0c0c0;
    font-size: 12px;
    margin: 5px;
    cursor: pointer;
  }
}

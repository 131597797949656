.fields-inline {
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-bottom: 1px solid #a5a5a5;
  border-top: 1px solid #a5a5a5;

  &:first-child {
    border-left: 1px solid #a5a5a5;
    margin-left: 15px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  &:last-child {
    border-right: 1px solid #a5a5a5;
    margin-right: 15px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .simple-input {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    margin-right: 0 !important;
    label {
      background-color: #a5a5a5;
      color: #fff;
      display: block;
      margin-bottom: 0;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 8%;
      margin-left: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input {
      width: 90%;
      margin-left: 5%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .template-min-max-component {
    margin-bottom: 0 !important;
    .label-wrap {
      width: 100% !important;
      background-color: #a5a5a5 !important;

      label {
        width: 100% !important;
        text-align: left !important;
      }
    }
    .fields-wrap {
      input:last-child {
        margin-right: 0.5rem !important;
      }
    }
  }
}

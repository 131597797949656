.simple-input {
  label {
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 5px;
  }

  input,
  select,
  textarea {
    height: 36px;
    font-size: 14px;
    &:placeholder-shown {
      color: var(--wgtLightGray) !important;
      font-style: italic;
    }
    border-color: var(--wgtInputBorder) !important;
  }
  span {
    float: right;
    font-size: 10px !important;
  }

  .required-border {
    border-color: var(--wgtRed) !important;
  }

  &.template-input-component.component-third {
    flex-direction: column !important;

    border: 1px solid #d8d7d7;
    border-radius: 8px;
    label {
      display: flex !important;
      align-items: center;
      flex-direction: row !important;
      justify-content: flex-start !important;
      background-color: #488bb1;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      width: 100%;
      border-bottom: 1px solid #d8d7d7;
      height: 36px;
      margin: 0 !important;
      color: #ffffff;
      text-align: left;
      padding-left: 0.8rem;
    }

    input {
      flex: 1;
      margin: 0.25rem 0 !important;
      width: 95%;
    }
  }
}

.upload-card-component {
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.15) !important;
  padding: 20px;
  display: block span {
    color: var(--wgtGray);
    margin-left: 10px;
  }
  .input-holder {
    border: 1px solid var(--wgtGray);
    border-radius: 5px;
    height: 35px;
    margin: 10px 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .empty {
      cursor: pointer;
      flex: 1;
      height: 30px;
    }

    .upload-cancel-icon {
      color: var(--wgtGray);
      cursor: pointer;
    }
  }
  .button-upload-holder {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  input.file-input {
    display: none;
  }
}

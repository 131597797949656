button {
  &.navbar-currency {
    font-size: 14px;
    padding-top: 7px;
    font-family: 'noto_sansregular' !important;
    img {
      height: 15px;
      margin-left: 10px;
      margin-bottom: 3px;
      border-radius: .15rem!important;
    }
  }
}

.search-screen-content-holder {
  .search-form-holder {
    border: 1px solid var(--wgtGray);

    .common-form-holder {
      .row {
        display: flex;

        label {
          margin-top: 10px;
          font-size: 12px;
          float: right;
        }
      }

      .input-group-between {
        display: flex !important;
        justify-content: space-between !important;
        > div {
          flex: 1 1 auto !important;
        }
      }

      .simple-input {
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    .search-form-tablist {
      background-color: var(--wgtLightGray);

      span {
        background: none;
        color: var(--wgtPrimary) !important;

        &.active {
          font-weight: bolder;
        }
      }
    }

    .segment-control {
      background-color: var(--wgtLightGray);
      border-radius: 20px;
      overflow: hidden;
      border: 1px solid var(--wgtDarkGray);
      padding: 1px;
      height: 40px;

      div {
        margin: 0;
        background: none;
        box-shadow: none;

        label {
          margin: 0;
          color: var(--wgtDarkGray);
          font-size: 14px;
        }

        &[data-active='true'] {
          background-color: var(--wgtPrimary) !important;
          border-radius: 25px;

          label {
            color: #fff;
          }
        }
      }
    }

    .input-slider {
      margin-bottom: 1.5rem !important;
      border: 1px solid #fff;
      display: flex;

      label {
        width: 165px;
        margin-right: 10px;
        text-align: right;
        margin-top: 5px;
      }

      .rangeslider {
        width: 100%;
      }
    }

    .expanded-dropdown-color {
      margin-left: 0.5rem !important;
      margin-bottom: 1.5rem !important;
    }

    .dropdown-popover-select {
      > div {
        display: flex !important;
        > span {
          align-self: center !important;
        }
      }
    }

    .fields-inline {
      .simple-input.template-input-component {
        display: flex !important;
        align-items: flex-start !important;
        flex-direction: column !important;
        margin-bottom: 0;
        label {
          text-align: left !important;
          width: 100%;
        }
      }
    }
  }
  .available-tab-template-fields {
    .form-inline {
      justify-content: flex-end;
      margin-right: 100px;
      margin-bottom: 20px;
    }
  }
}

.inline-update-product {
  label,
  span {
    font-size: 12px;
    margin-right: 10px;
  }

  .simple-dropdown,
  .simple-input,
  .simple-textarea {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;

    input,
    select,
    textarea {
      width: 200px;
    }
  }

  .media-and-report {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 5px;
      border: 1px solid var(--wgtPrimary);
      color: var(--wgtPrimary);
      cursor: pointer;
      background: none;
      font-size: 12px;

      i {
        background-color: rgb(255, 115, 0);
        height: 22px;
        width: 22px;
        overflow: hidden;
        font-size: 10px;
        text-align: center;
        padding: 4px;
        color: #fff;
        border-radius: 20px;
        position: absolute;
        margin-top: -15px;
      }

      &.primary {
        border: 1px solid var(--primaryButton);
        color: var(--primaryButton);
      }
    }
  }
}
.created-at-range-selector {
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  padding: 5px 2px;
  svg {
    margin: 0px 10px;
  }
}

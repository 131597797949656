@import '../../assets/scss/variables';

.template-characteristic {
  span[role='tab'] {
    cursor: pointer;
    border: 1px solid $blue-02;
    color: $blue-02 !important;
    margin: 0 1rem 1rem 0 !important;
    border-radius: 5px;
    padding: 3px 15px;
    vertical-align: middle;
    text-decoration: none;
    align-items: center;
    font-size: 13px !important;
    width: 130px;
    height: 45px;
    text-align: center;

    &:hover,
    &.selected {
      background-color: $blue-02;
      color: $white-01 !important;
    }

    &:focus {
      box-shadow: unset;
    }
  }

  .template-characteristic-values {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .template-characteristic-value-label {
      margin: 0 1rem 1rem 0 !important;
      cursor: pointer;
      border: 1px solid $gray-07;
      color: $gray-05;
      border-radius: 5px;
      padding: 3px 15px;
      vertical-align: middle;
      text-decoration: none;
      align-items: center;
      font-size: 12px;
      width: 130px;
      min-height: 35px;
      text-align: center;
      display: flex;
      justify-content: center !important;
      align-items: center !important;

      &.hasSelected,
      &:hover {
        background-color: $gray-07;
      }
    }

    input.template-characteristic-value[type='checkbox'] {
      display: none;
    }

    input:checked + label.template-characteristic-value-label {
      background-color: $gray-07;
    }

    .template-characteristic-value-variations {
      position: absolute;
      background: #fff;
      border: 1px solid #e4e8ef;
      padding: 10px;
      z-index: 1;
      margin-left: 20px;
      border-radius: 5px;
      width: 300px;

      h5 {
        color: var(--wgtPrimary);
        font-weight: bold;
        margin-top: 10px;
        font-size: 13px;
        text-align: left !important;
      }

      .template-characteristic-value-variations-content {
        padding: 0;
        margin: 0;
        list-style: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;

        li {
          vertical-align: middle;
          font-size: 12px;
          margin: 0;
          text-align: left;
          input {
            position: relative;
            top: 2px;
          }
          label {
            color: #1e87f6;
            text-decoration: underline;
            margin-right: 5px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}

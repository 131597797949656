@import '../../assets/scss/variables';

.wgt-check-button-variations {
  align-items: flex-start;
  display: flex;

  input[type='radio'] {
    display: none;
  }

  label.form-check-label {
    cursor: pointer;
    border: 1px solid $gray-07;
    border-radius: 0.4rem;
    padding: 3px 15px;
    vertical-align: middle;
    text-decoration: none;
    align-items: center;
    font-size: 13px;
    margin-right: 1rem;

    &:first-child {
      border: 1px solid $blue-02;
      background-color: $blue-02;
      color: $white-01;
      margin-top: 23px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 4px;
      padding-bottom: 3px;
    }
  }

  &.invalid {
    label {
      border: 1px solid var(--wgtRed);
    }
    .variations-options > label {
      border: 1px solid #fff;
    }
    input.field-value-variation-input + label {
      border: 1px solid var(--wgtRed) !important;
    }
  }

  .variations-options {
    margin-right: 1rem;

    > label {
      margin-bottom: 0 !important;
      font-size: 11px;
      color: $gray-08;
    }

    input.field-value-variation-input {
      display: none;
    }

    input.field-value-variation-input + label {
      cursor: pointer;
      border: 1px solid $gray-07;
      color: $gray-05;
      border-radius: 0.4rem;
      padding: 3px 15px;
      text-decoration: none;
      align-items: center;
      font-size: 13px;
      margin-bottom: 0;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      &:hover {
        background-color: $gray-07;
      }
    }

    input.field-value-variation-input:checked + label {
      background-color: $gray-07;
    }
  }
}

.custom-input {
  display: flex;

  label {
    margin-bottom: 0 !important;
    position: relative;
    top: 8px;
  }

  input {
    &:placeholder-shown {
      background-color: var(--wgtLightGray) !important;
    }
  }
  span {
    float: right;
    font-size: 10px !important;
    margin-top: 5px;
  }
}

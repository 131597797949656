.firm-card {
  padding: 20px;
  box-shadow: var(--wgtBoxShadow);
  border-radius: 5px;

  &.selected {
    border: 2px solid var(--primaryButton);
    background-color: #f8f8f8;
  }

  .firm-card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    .firm-card-avatar {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border: 1px solid;
      border-radius: 5px;
      margin-bottom: 20px;
      img {
        width: 50px;
      }
    }

    .firm-card-icon {
      cursor: pointer;
    }
  }
  h2 {
    line-height: 25px;
  }

  p {
    margin: 20px 0;
    font-size: 12px !important;
  }

  .firm-card-button-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.website-template {
    .photo-preview {
        border: 1px dashed #9b9c9c;
        img {
            width: 30%;
        }
    }

    .photo-holder {
        .img {
            width: 30%;
        }
    }
}

.download-btn {
  background-color: white;
  border: 1px solid #dfdbdb;
  padding: 8px 10px;
  border-radius: 5px;

  svg {
    margin-right: 5px;
  }
}

.bg-white {
  &.isAddStock {
    border: 2px solid #c0c0c0;
    border-radius: 10px;
    padding: 10px;
  }
}
.form-shape-checkbox {
  flex-basis: 30%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    height: 10px;
    margin-left: 10px;
  }
  label {
    font-size: 12px;
  }
}

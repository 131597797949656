@import '../../assets/scss/scrollbar';

.popover-workOrderType {
  p,
  label {
    font-family: 'noto_sansregular' !important;
    font-size: 14px !important;
  }

  .popover {
    min-width: 365px;
  }

  .title {
    padding-top: 0.5rem;
    color: var(--wgtPrimary);
    font-size: 15px !important;
  }

  .list-checkbox > div > label > input[type='radio']:first-child {
    width: 14px !important;
    height: 14px !important;
  }

  input[type='radio'] {
    width: 15px;
    height: 15px;
  }

  @include scrollbar;

  .popover-scroll {
    min-height: 320px;
    max-height: 40 0px;
    overflow-y: auto;
  }

  .is-invalid {
    border-color: #dc3545 !important;
  }

  .btn-icon-more-items {
    height: 16px;
    margin-top: 2px;
    color: #212529;
    &:hover {
      background-color: #fff !important;
    }
  }

  $columns: 5;

  @for $i from 1 through $columns {
    .ml-tree-#{$i} {
      margin-left: ($i * 0.5rem);
    }
  }
}

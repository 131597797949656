#point-of-sales {
  .font-size-14 {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .button-input-popover-field {
    border-color: var(--wgtInputBorder) !important;
  }

  #resume {
    div {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .operator {
      width: 9%;
      span {
        font-size: 18px !important;
        color: var(--wgtTextColor);
        margin-bottom: 0;
      }
    }

    .section-totals {
      .operator {
        width: 4%;
      }
    }

    .title {
      line-height: 0.8 !important;
    }

    .descrition-title {
      line-height: 2.5 !important;
      font-size: 14px !important;
    }

    .iconUpdate {
      color: var(--wgtGray);
      height: 16px;
      width: 16px;
      &:hover {
        background: none !important;
      }
    }

    .total-due,
    .sub-total,
    .interest,
    .shipping,
    .custom,
    .tax,
    .paid,
    .discount {
      .amount {
        font-size: 16px !important;
        line-height: 19px;
        letter-spacing: 0.05rem;
        color: var(--wgtTextColor) !important;
      }
      .text-muted {
        font-style: italic;
      }
    }

    .sub-total {
      .amount {
        font-weight: bold;
      }
      .text-muted {
        font-style: normal;
        color: var(--wgtTextColor) !important;
        font-size: 12px !important;
      }
    }

    .discount {
      .amount {
        color: var(--wgtRed) !important;
      }
      .text-muted {
        color: var(--wgtRed) !important;
      }
      .operator span {
        color: var(--wgtRed) !important;
      }
    }

    .total {
      .amount {
        font-weight: bold;
        font-size: 20px !important;
        line-height: 24px;
        letter-spacing: 0.05rem;
        color: var(--switchButtonSelected) !important;
      }
      .text-muted {
        color: var(--wgtTextColor) !important;
      }
    }

    .total-due {
      .amount {
        font-weight: bold;
        color: var(--wgtRed) !important;
      }
      .text-muted {
        font-style: normal;
        color: var(--wgtRed) !important;
      }
    }
  }

  .open-margins {
    .card-header {
      .card-header-title {
        color: var(--switchButtonSelected) !important;
        font-size: 14px !important;
        font-weight: bold;
      }

      .text-muted {
        font-size: 12px;
        line-height: 22px;
      }

      .password-input {
        height: 33px;
      }

      .add-button {
        margin-top: -4px;
      }
    }

    .card-footer {
      &.border-bold {
        border-top-width: 2px;
        border-top-color: #e4e8ef;
        opacity: 0.6;
      }

      select {
        border-color: var(--wgtInputText) !important;
        color: black !important;
      }
    }
  }

  #stock-options {
    .option-selected {
      > div:first-child {
        border: 1px solid #f5950e !important;
      }
    }

    .buttons-option {
      background-color: #b8e5c4;
      border: 1px solid rgba(0, 116, 160, 0.79) !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 9px;

      .background-shallow {
        background: rgba(138, 202, 38, 0.39) !important;
        border: 2px solid #1e87f6 !important;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        border-radius: 9px !important;

        .background-dive {
          background: #26cab6 !important;
          box-shadow: 0 0 0 2px #1ca7e3, inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
          border-radius: 9px !important;
        }

        .header-title {
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          text-align: center;
          width: 100%;
          display: block;
          font-weight: bold;
        }

        .custom-button {
          cursor: inherit;
          background: #ffffff;
          border: 1px solid #1e87f6 !important;
          border-radius: 7px !important;
          color: #0074a0 !important;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

          &.selected {
            background-color: rgba(138, 202, 38, 0.39);
            border-color: #fff !important;
            color: #fff !important;
          }
        }
      }
    }

    .nav-link {
      cursor: pointer !important;
      color: var(--wgtTextColor);
      &:hover {
        color: unset;
      }
    }

    #shipping,
    #policy-type {
      height: 120px;
      span {
        font-size: 14px !important;
        display: block;
        color: var(--wgtTextColor);
      }
      .header {
        height: 45px;
      }
      .iconFileText {
        font-size: 40px;
        stroke-width: 1;
      }
      .iconShipping {
        height: 35px;
      }
    }
  }

  #header-results {
    > div:first-child {
      height: 65px;
      margin-top: 1.5rem;
    }

    .line-margin-bottom {
      margin-bottom: 1rem !important;
    }

    .badge-total {
      border: 1px solid var(--wgtGray);
      border-radius: 0.5rem;
      padding: 0.3rem 1rem;
      span {
        line-height: 20px;
        font-size: 14px !important;
        color: var(--wgtDarkGray);
      }
    }

    .learn-to-use-margin-left {
      margin-right: 9rem;
    }
  }

  #result {
    .header {
      font-size: 14px !important;
      color: var(--wgtDarkGray) !important;
      div:first-child {
        width: 132px;
      }
      div:last-child {
        text-align: right;
      }
    }

    .icon-toggle {
      height: 28px;
      margin-left: 10px;
      svg {
        color: var(--wgtDarkGray);
        stroke-width: 1 !important;
      }
    }
    .items {
      &.highlight {
        border-color: rgba(0, 116, 160, 0.79) !important;
        border-width: 1px !important;
        background-color: rgba(0, 116, 160, 0.05) !important;
      }

      > div {
        align-self: center !important;
        span {
          font-size: 14px !important;
          color: var(--wgtTextColor);
          &.font-small {
            font-size: 12px !important;
          }
        }
        &:nth-last-of-type(2) {
          display: flex;
          justify-content: flex-end !important;
        }
      }

      .image-media {
        width: 35px;
        height: 35px;
        margin-left: 10px;
      }

      div {
        font-size: 14px !important;
        color: var(--wgtTextColor);
        span {
          font-size: 14px !important;
          color: var(--wgtTextColor);
        }
      }
      .point-of-sale-collapse-content {
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        margin-top: 0.5rem;
        padding: 1.5rem;
      }
    }

    .item-action-buttons {
      .secondary,
      .orange {
        height: 36px !important;
      }
    }

    .product-ids {
      span {
        display: block;
        padding-left: 1.5rem;
        margin-bottom: 0.25rem;
      }
    }

    dt {
      font-weight: normal;
      padding-right: 0;
    }
    dd {
      font-weight: bold;
    }

    .btn-edit-common-attributes {
      position: absolute;
      top: 1rem;
      right: 0;
    }
    .btn-load-more {
      border: none;
      color: var(--wgtDarkGray);
      box-shadow: none;
    }

    .page-ids-details {
      .page-ids {
        min-width: 100%;
        margin-bottom: 0;
      }
    }
  }
}

.confirm-modal {
  background-color: #123753;
  color: #fff;

  p {
    text-align: center;
    font-size: 16px !important;
    font-style: italic;
    line-height: 25px !important;
  }
  button {
    margin: 0 10px;
  }

  small {
    color: #929a9c;
  }
}

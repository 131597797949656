.work-order-section {
  .font-size-14 {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .image-media {
    width: 35px;
    height: 35px;
    margin-left: 10px;
  }

  .button-input-popover-field {
    border-color: var(--wgtInputBorder) !important;
  }
  .card-header {
    .card-header-title {
      color: var(--switchButtonSelected) !important;
      font-size: 14px !important;
      font-weight: bold;
    }

    .text-muted {
      font-size: 12px;
      line-height: 22px;
    }

    .password-input {
      height: 33px;
    }

    .add-button {
      margin-top: -4px;
    }
  }

  .card-footer {
    &.border-bold {
      border-top-width: 2px;
      border-top-color: #e4e8ef;
      opacity: 0.6;
    }

    select {
      border-color: var(--wgtInputText) !important;
      color: black !important;
    }
  }

  .margin-work-order-avatar {
    @media (max-width: 575px) {
      padding-left: 43px;
    }
  }

  .work-order-item-price {
    width: 100px;
    padding: 5px 3px 5px 0;
  }

  .btn-load-open-work {
    height: 36px;
  }

  .actived-open-work {
    padding-top: 20px;
  }

  .job-order-list {
    border: 1px solid #1e87f6 !important;
    background: #f7fafd !important;
    border-radius: 0.25rem !important;
  }

  .input-hight-width {
    width: 300px;
  }

  .image-order-item {
    width: 50px;
    background-color: unset;
    border: unset;
  }

  .icon-collapse-job-order-worder {
    svg {
      color: var(--mutedColor) !important;
    }
  }

  .job-order-worker {
    .react-datepicker-wrapper {
      width: 100%;
    }

    .thumb-size-job-order-worker {
      width: 50px;
      background-color: unset;
      border: unset;
    }

    .checkbox-private-details {
      display: flex;
      align-items: center;
      label {
        display: inline-block;
        display: flex;
        align-items: center;
        svg {
          margin-left: 0.25rem;
        }
      }
      input {
        position: unset;
        margin-left: 0;
        margin-top: 0;
      }
    }

    .textarea-detail-height {
      height: 100px;
    }

    .btn-time-tracking:hover {
      background-color: var(--wgtRed) !important;
    }

    .btn-time-tracking {
      svg {
        color: #fff;
        font-size: 25px;
        height: 16px;
        width: 16px;
        margin-top: 0;
      }
    }

    .box-field-work-order {
      height: 70px;
      margin-bottom: 0 !important;
      align-items: flex-end !important;
      display: flex !important;

      .simple-input input {
        width: 95px;
      }

      .simple-dropdown select {
        width: 83px;
        height: 36px;
      }
    }

    .work-order-notes {
      height: 100px;
    }
  }
}

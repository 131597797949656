.return-button {
  width: 100%;
  flex: 1;
  padding-bottom: 10px;
  margin-bottom: 10px;

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    svg {
      margin-right: 5px;
    }
  }
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v18-latin-300.eot');
  src: local(''),
    url('../fonts/open-sans-v18-latin-300.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans-v18-latin-300.woff2') format('woff2'),
    url('../fonts/open-sans-v18-latin-300.woff') format('woff'),
    url('../fonts/open-sans-v18-latin-300.ttf') format('truetype'),
    url('../fonts/open-sans-v18-latin-300.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v18-latin-regular.eot');
  src: local(''),
    url('../fonts/open-sans-v18-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
    url('../fonts/open-sans-v18-latin-regular.woff') format('woff'),
    url('../fonts/open-sans-v18-latin-regular.ttf') format('truetype'),
    url('../fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v18-latin-600.eot');
  src: local(''),
    url('../fonts/open-sans-v18-latin-600.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans-v18-latin-600.woff2') format('woff2'),
    url('../fonts/open-sans-v18-latin-600.woff') format('woff'),
    url('../fonts/open-sans-v18-latin-600.ttf') format('truetype'),
    url('../fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v18-latin-700.eot');
  src: local(''),
    url('../fonts/open-sans-v18-latin-700.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans-v18-latin-700.woff2') format('woff2'),
    url('../fonts/open-sans-v18-latin-700.woff') format('woff'),
    url('../fonts/open-sans-v18-latin-700.ttf') format('truetype'),
    url('../fonts/open-sans-v18-latin-700.svg#OpenSans') format('svg');
}

.confirm-password-component {
  .confirm-password-content {
    margin-bottom: -5px;
    display: flex;
    flex-direction: row;

    justify-content: center;

    label {
      margin-bottom: 0 !important;
      position: relative;
      margin-right: -20px;
      color: var(--wgtGray);
      font-size: 13px;
      text-align: right;
      width: 180px;
    }
    input {
      margin: 0 20px;
    }
  }
  span {
    float: right;
    font-size: 10px !important;
  }
}

.expanded-dropdown-color {
  label.custom-checkbox-color {
    border-color: #fff;
    border: 1px solid;
    padding: 5px;
    min-width: 4rem;
    justify-content: center;
    align-content: center;
    display: flex;
    cursor: pointer;

    input {
      display: none;
    }
  }
  span {
    font-family: 'noto_sansregular' !important;
    font-size: 14px !important;
    color: var(--wgtTextColor) !important;
  }
}

@import '../../../../../assets/scss/variables';

.product-page-list {
  .header-page-list {
    border: 1px solid $gray-07;
    background-color: $gray-03;
    p {
      font-weight: 700;
      color: $gray-05;
    }
  }
  .card .card-header {
    border-bottom: 0 !important;
  }
}

@import '../../assets/scss/variables';

.price-holder {
  border: 1px solid $gray-09;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;

  &:first-child {
    margin-right: 1rem;
  }

  .title {
    background-color: $gray-11;
    color: $white-01;
    padding: 5px 0.4rem;
    font-size: 13px;
    border-bottom: 1px solid $gray-09;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: $gray-05 !important;
    position: relative;

    &.disabled {
      background-color: #e9ecef;
      opacity: 1;
    }

    .text-sup {
      font-size: 12px;
      color: $gray-05 !important;
      position: absolute;
      right: 5px;
      top: 5px;
    }

    input {
      color: $gray-05 !important;
      border: unset;
      background: none;
      width: 100%;
      padding: 5px;
      font-size: 14px;

      &:disabled {
        background-color: #e9ecef;
        opacity: 1;
        border-color: #bdbdbd !important;
      }
    }
  }
}

.media-center-minerals {
  height: 130px;
  background-color: #000;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  .add-media-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 103px;
    border: 1px solid #92999c;
    cursor: pointer;
    height: 100px;
  }
}

.minerals-table {
  font-size: 14px;
  .head {
    margin-left: 0 !important;
    margin-right: 0 !important;
    div {
      font-family: 'Noto Serif' !important;
      color: var(--primaryColor);
    }
  }

  .mineral-body {
    > div {
      display: flex;
      align-items: center;
    }
  }

  .icon-toggle {
    height: 28px;

    svg {
      color: var(--wgtDarkGray);
      stroke-width: 1 !important;
    }
  }
}

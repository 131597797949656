@import '../../assets/scss/variables';

.simple-textarea {
  label {
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 5px;
  }

  textarea {
    &:placeholder-shown {
      background-color: var(--wgtLightGray) !important;
    }
    border-color: var(--wgtInputBorder) !important;
  }
  span {
    float: right;
    font-size: 10px !important;
  }
}

.switch-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  label {
    white-space: nowrap;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .switch-component-switcher {
    margin: 0 10px;
  }
  .switch-component-tolltip {
    cursor: pointer;
    color: var(--wgtGray);
  }
}

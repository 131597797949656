.simple-upload-button {
  label {
    margin-left: 10px;
    font-size: 14px;
  }

  input[type='file'] {
    width: 0;
  }
}

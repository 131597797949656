@import "../../../../assets/scss/variables";

.page-ids-route {
    border: 1px solid $gray-09 !important;
    background-color: $gray-03;
    width: 350px;
    button {
        width: 215px;

        svg {
            fill: $gray-08 !important;
        }
    }
    .input-group {
        // height: 26px;

        .input-group-prepend {
            height: 100%;
            .input-group-text {
                background-color: $gray-01;
                p {
                    color: $white-01;
                }

                &.page-id-index {
                    background-color: $blue-02;
                }
            }
        }
    }
}

.card-group {
    &.common-attributes-card {
        @media (min-width: 992px) {
            flex-flow: row wrap;
            flex-direction: row !important;
        }
        @media (max-width: 991px) {
            flex-flow: row wrap;
            flex-direction: column !important;
        }
    }
}

.max-width-100 {
    max-width: 100% !important;
}

.page-id-button-content {
    white-space: nowrap;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
  &.small > div{
    width: 8px;
    height: 8px;
    margin: 8px 0.1rem;
  }

}
.bouncing-loader > div {
  width: 1rem;
  height: 1rem;
  margin: 3rem 0.2rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;


}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}


.round-loader,
.round-loader:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;

 
}
.round-loader {
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid var(--wgtPrimary);
  border-right: 0.5em solid var(--wgtPrimary);
  border-bottom: 0.5em solid var(--wgtPrimary);
  border-left: 0.5em solid  #c4b7b7;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 0.8s infinite linear;

  &.small {
    width: 15px;
    height: 15px;
    font-size: 5px;
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
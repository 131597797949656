.template-search-layout-dnd {
  .main-section {
    border-style: dashed !important;
    min-height: 270px;
    .common-attributes {
      width: 40%;

      label {
        font-size: 12px;
        line-height: 26px;
      }
    }
  }

  .second-section {
    border-style: dashed !important;
  }
}

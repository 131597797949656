@import '../../assets/scss/scrollbar';

.shape-selector {
  @include scrollbar(rgba(0, 0, 0, 0.2), #fff);
  .overflow-x-auto {
    overflow-x: auto;
  }
  .card-block {
    max-width: 100px;
    border: 1px solid #fff;
    .text-footer {
      line-height: 1.3;
      color: #373c3e;
      padding-bottom: 0.2rem;
      padding-top: calc(0.2rem + 2px);
      min-height: 57px;
      font-style: italic;
    }

    &.selected {
      border-color: #0074a0 !important;
    }
  }
}

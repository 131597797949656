.input-slider {
  label {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 14px;
  }

  .rangeslider {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .rangeslider__handle-label {
    bottom: -23px;
    position: absolute;
    width: 30px;
    text-align: center;
    font-size: 14px;
  }

  &.disabled {
    .rangeslider-horizontal .rangeslider__fill {
      background-color: #b1cd8a !important;
    }
  }
}

#modal-receipt {
    .modal-content {
      border: none;
  
      .modal-header {
        background-color: #e5e5e5;
  
        .modal-title {
          font-weight: bold !important;
          color: var(--wgtTextColor) !important;
          font-family: 'noto_sansregular' !important;
          font-size: 16px !important;
          line-height: 1.3 !important;
        }
  
        button {
          background-color: #fff;
          &:hover {
            background-color: #6c757d;
          }
        }
      }
  
      .modal-body {
        .icons-option {
          border-radius: 0.25rem;
          border: 1px solid #dee2e6;
          padding: 2px;
          .icon-mail {
            width: 50%;
          }
  
          .icon-file {
            stroke-width: 0.5;
          }
        }
  
        .currency-input {
          label {
            margin-left: 0 !important;
          }
          .flag-currency {
            height: 14px;
          }
        }
  
        .badge-receipt {
          border: 1px solid var(--wgtDarkGray);
          background-color: #fff;
          padding: 0.25rem;
          svg {
            &:last-child {
              margin-left: 0.75rem;
              color: var(--wgtDarkGray);
            }
            &.blue {
              color: var(--linkColor);
            }
            &.orange {
              color: var(--wgt-orange);
            }
          }
        }
  
        .badge-number {
          font-size: 10px !important;
          line-height: 1.5 !important;
          background-color: var(--wgt-orange);
          color: white;
          padding-top: 0.05rem;
          padding-bottom: 0.05rem;
        }
  
        .container-select-badge {
          background-color: var(--wgtGray);
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
  
        .card-value {
          &.card {
            border: 0 !important;
          }
          .card-header {
            font-size: 12px;
            background-color: rgba(216, 215, 215, 0.46);
          }
  
          .card-body {
            background-color: #f9f9f9;
            input.form-control {
              height: 28px;
            }
          }
        }
  
        .button.link {
          color: var(--wgtDarkGray);
        }
  
        .react-datepicker-wrapper {
          input {
            font-size: 14px;
          }
        }
      }
      .modal-footer {
        .label-password {
          display: inline-block;
          font-size: 11px;
          width: 102px;
        }
      }
    }
  }
  
.urgency-selector-container {
  width: 160px;
  .urgency-selector-item {
    display: flex;
    align-items: center;
    .urgency-selector-item-color {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      margin-right: 10px;
    }
  }
}

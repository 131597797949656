.template-layout-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-right: -15px;
  margin-left: -15px;
  height: 73vh;

  .template-layout-container-sidebar {
    background-color: #e5e5e5;
    width: 20vw;
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    .template-layout-container-sidebar-item {
      padding: 5px 0;
      small {
        text-transform: capitalize;
      }
    }
  }
  .template-layout-container-content {
    background-color: #fff;
    flex: 1;
    margin: 20px;
    height: 100%;
    overflow-y: auto;

    .template-layout-container-structure-row {
      margin-bottom: 40px;
      border: 1px dashed #d6d5d5;

      .template-layout-container-structure-column {
        border: 1px dashed #d6d5d5;
        display: flex;
        justify-content: center;
        padding: 0px;

        .template-layout-container-group-form {
          width: 100%;
          padding: 10px;

          .template-layout-container-group-form-remove-column-btn {
            float: right;
          }
        }

        .font-small {
          font-size: 12px !important;
        }
      }
    }
  }
}
.group-title-form-holder {
  label {
    min-width: 180px;

    &.placeholder {
      font-size: 12px;
      color: var(--wgtGray);
    }
  }

  svg {
    margin-left: 10px;
    cursor: pointer;
  }
}

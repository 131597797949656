button {
  outline: none !important;
}

button.button {
  height: 38px;
  font-size: 14px !important;
  background-image: none !important;

  svg {
    margin-top: -3px;
  }

  &.text {
    all: unset;
    cursor: pointer !important;
  }
  &.primary {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25) !important;
    border: solid 1px rgba(55, 60, 62, 0.2) !important;
    background-color: var(--primaryButton) !important;

    &:hover {
      background-color: var(--primaryButton) !important;
    }

    &.btn-trace-document {
      background-color: #7ca3cb !important;
      background-image: none !important;
      border-color: #587fa7 !important;
      border-color: #587fa7 !important;
      color: #fff !important;
      height: 36px !important;
      svg {
        color: #fff !important;
      }
    }
  }
  &.danger {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25) !important;
    border: solid 1px rgba(55, 60, 62, 0.2) !important;
    background-color: var(--wgtRed) !important;

    &:hover {
      background-color: var(--mutedColor) !important;
    }
  }

  &.secondary {
    color: var(--primaryButton) !important;
    border: 1px solid var(--primaryButton) !important;
    background-color: none !important;
    box-shadow: none !important;

    &:hover {
      background-color: none !important;
    }
  }

  &.blue {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25) !important;
    border: solid 1px rgba(55, 60, 62, 0.2) !important;
    background-color: var(--wgtPrimary) !important;

    &:hover {
      background-color: var(--wgtPrimary) !important;
    }

    &.outline {
      background-color: #fff !important;
      color: var(--wgtPrimary) !important;
      border: 1px solid var(--wgtPrimary) !important;
      box-shadow: unset !important;
      height: 36px;
      line-height: 1;
      &:hover {
        background-color: var(--wgtPrimary) !important;
        color: #fff !important;
      }
    }
  }
  &.inactive {
    color: var(--disabledButton) !important;
    border: 1px solid var(--disabledButton) !important;
    background-color: none !important;
    box-shadow: none !important;

    &:hover {
      background-color: none !important;
    }
  }
  &.small {
    height: 22px !important;
    padding: 0 10px;
    font-size: 10px !important;
    line-height: 10px;
  }
  &.button-delete {
    border: 0 !important;
    padding: 0 !important;
    background: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    display: inline-block;
    margin: 0px !important;
    margin-top: -9px !important;
  }
  &.combobox {
    width: 100%;
    color: #495057;
    height: 36px !important;
    font-family: 'noto_sansregular' !important;
  }
  &.orange {
    background-color: var(--wgt-orange) !important;
    color: #fff;
    &.outline {
      background-color: #fff !important;
      color: var(--wgt-orange) !important;
      border: 1px solid var(--wgt-orange) !important;
      box-shadow: unset;
    }
  }
  &.gray {
    background-color: var(--wgtDarkGray) !important;
    color: #fff;
    &.outline {
      background-color: #fff !important;
      color: var(--wgtDarkGray) !important;
      border: 1px solid var(--wgtDarkGray) !important;
      box-shadow: unset;
    }
  }
  &.white {
    background-color: #fff !important;
    color: #000;
    &.outline {
      background-color: #fff !important;
      color: var(--wgtDarkGray) !important;
      border: 1px solid var(--wgtDarkGray) !important;
      box-shadow: unset;
    }
  }
  &.danger-outline {
    background-color: #fff !important;
    color: #d31a1a;
    border: 1px solid #d31a1a !important;
    &.outline {
      background-color: #d31a1a !important;
      color: #d31a1a !important;
      border: 1px solid #d31a1a !important;
      box-shadow: unset;
    }
  }
  &.btn-outline-secondary {
    box-shadow: unset;
  }

  &.is-invalid {
    border: 1px solid var(--wgtRed);
  }

  .button-pill {
    position: absolute;
    top: -8px;
    &.right {
      right: -13px;
    }
    &.left {
      left: -13px;
    }
  }
}

a.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  background-color: #fff;
  height: 38px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  &:hover {
    border-color: #6c757d;
    color: #fff;
  }
}

a.btn-primary {
  color: #fff !important;
  border-color: var(--primaryButton) !important;
  background-color: var(--primaryButton) !important;
  height: 38px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  &:hover {
    border-color: var(--primaryButton) !important;
    background-color: #fff !important;
    color: var(--primaryButton) !important;
  }
  &.small {
    height: 22px !important;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px !important;
    line-height: 6px;
  }
}

a.btn-outline-primary {
  color: var(--primaryButton) !important;
  border-color: var(--primaryButton) !important;
  background-color: #fff;
  height: 38px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  &:hover {
    border-color: var(--primaryButton) !important;
    color: #fff !important;
    background-color: var(--primaryButton) !important;
  }
  &.small {
    height: 22px !important;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px !important;
    line-height: 6px;
  }
}

.btn-circle {
  width: 25px;
  height: 25px;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 11px !important;
  line-height: 1.428571429 !important;
  border-radius: 50% !important;
  border: 1px solid var(--wgtDarkGray) !important;
}

.icon-toggle {
  height: 28px;
  svg {
    margin-top: 1px !important;
    color: var(--wgtDarkGray);
    stroke-width: 1 !important;
  }
}

.btn-outline-learn-to-use {
  color: #6c757d !important;
  border-color: #6c757d !important;
  background-color: #fff;
  font-weight: 500 !important;
  padding-bottom: 5px !important;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
  }
}

.form-with-erase {
  .form-with-erase-content {
    display: flex;
    align-items: center;

    button {
      background: none !important;
      background-image: none !important;
      border: none !important;
      margin-top: 23px;
    }
  }
  span {
    float: right;
    font-size: 10px !important;
    margin-top: -10px;
  }
}

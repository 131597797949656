@mixin mixins-policy-card {
  position: relative;
  span {
    font-size: 14px !important;
    display: block;
    color: var(--wgtTextColor);
  }
  .box-title {
    font-size: 15px !important;
  }
  .box-text {
    font-size: 14px !important;
  }
  .iconFileText {
    font-size: 45px;
    stroke-width: 0.5;
    color: var(--wgtGray);
  }
  .button-close {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--wgtDarkGray);
    stroke-width: 1;
  }
}

.popover-card {
  .popover {
    border-radius: 0;
    border-color: #d8d7d7;
  }
  .popover-body {
    padding: 0 0;
  }
  .list-group {
    border-radius: 0;
  }
  .list-group-item {
    border: none;
    padding: 0.5rem 1.25rem;
  }
  .arrow {
    &::after {
      border-top-color: #fff !important;
    }
    &::before {
      border-top-color: #d8d7d7 !important;
      border-bottom-color: #d8d7d7 !important;
    }
  }
}

.popover-create-new-policy {
  .popover {
    max-width: 310px;
  }
  .title {
    color: var(--wgtPrimary);
    font-weight: 500;
  }

  .expiry-date-label {
    font-size: 14px;
    margin-left: 10px;
    color: #212529;
  }

  .simple-dropdown,
  .simple-input {
    &.input-small {
      select,
      input {
        height: 30px;
        font-size: 14px;
        padding-top: 4px;
      }
      label {
        font-size: 14px !important;
        color: #212529;
      }
    }
  }

  .label-small {
    label {
      color: var(--wgtDarkGray) !important;
      margin-top: 4px;
      margin-bottom: 2px;
    }
  }
}

#policy {
  .favourite-main {
    min-height: 40vh;
    display: flex;
    align-items: stretch;
  }

  .favourite-list {
    background-color: var(--wgtLighterGray);
    height: 100%;

    .policy-card {
      margin: 1rem;
      @include mixins-policy-card;
    }
  }

  .favourite-body {
    border: 2px solid var(--wgtDarkGray);
    height: 100%;
    background-color: var(--wgtLightGray);
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .office-share-policies {
    .policy-card {
      margin: 0 0 1rem 0;
      @include mixins-policy-card;
    }
  }

  .favourite-header {
    textarea {
      height: 90px !important;
    }
  }
}

.policy-content-bordered {
  border: 4px solid #c0c0c0;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;

  h2 {
    font-size: 14px !important;
    color: #d31a1a !important;

    &.muted {
      color: #929a9c !important;
      font-style: italic !important;
    }
  }

  label {
    font-size: 12px !important;
    font-weight: bold;
  }

  .col {
    border: 1px solid #c0c0c0;
    border-radius: 10px;
  }

  .field-schedule-item-bottom {
    color: #d31a1a !important;
  }
}

.expanded-simple-dropdown {
  select {
    font-family: 'noto_sansregular' !important;
    font-size: 14px !important;
    height: 36px;
  }

  span {
    font-size: 14px !important;
    color: var(--wgtTextColor);
  }
}

.statements-footer-items {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  background-color: #f4f6f8;
  padding: 10px;
  margin-bottom: 10px;

  > button {
    width: 180px;
    display: flex;
    flex-direction: column;
    border: 0;
    background: none;

    span {
      color: #1e87f6;
      text-decoration: underline;
    }
  }
}

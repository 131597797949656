.simple-input.template-min-max-component {
  display: flex;
  align-items: center;
  margin-bottom: unset;
  justify-content: flex-end;
  flex-direction: row !important;
  margin-right: 0;
  margin-bottom: 1rem;

  label {
    margin-bottom: 0;
    font-size: 12px;
    width: 6.5rem;
    text-align: right;
    margin-left: 0;
    margin-right: 1rem;
  }

  .fields-wrap {
    display: inline-flex;
    width: 100%;

    input {
      flex: 1;
    }
  }

  &.component-secondary {
    flex-direction: column !important;
    .label-wrap {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      margin-bottom: 0.5rem;

      label {
        background-color: #0074a0;
        color: #ffffff;
        border-color: #0074a0 !important;
        border-radius: 0.4rem;
        padding: 3px 15px;
        margin-bottom: 0.5rem;
        margin-left: 0 !important;
        text-align: center;
      }
    }

    .fields-wrap {
      flex-direction: row !important;
      display: flex !important;

      input {
        flex: 1;
      }
    }
  }

  &.component-third {
    flex-direction: column !important;
    border: 1px solid #d8d7d7;
    border-radius: 8px;
    .label-wrap {
      display: flex !important;
      align-items: center;
      flex-direction: row !important;
      justify-content: flex-start !important;
      background-color: #488bb1;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      width: 100%;
      border-bottom: 1px solid #d8d7d7;
      height: 36px;
      label {
        color: #ffffff;
        text-align: left;
        padding-left: 0.8rem;
      }
    }

    .fields-wrap {
      flex-direction: row !important;
      display: flex !important;
      padding: 0.5rem;
      input {
        flex: 1;
      }
    }
  }
}

.switch-box {
  border: 1px solid #e1e1e3;
  background-color: rgba(225, 225, 227, 0.2);
  border-radius: 30px;
  display: inline-block !important;
  padding: 0.2rem 1rem 0.2rem 3rem !important;

  label {
    font-size: 12px;
    padding-top: 3px;
  }

  &.selected {
    border: 1px solid #a9d3ab;
    background-color: #ecf7ed;

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #a9d3ab;
      background-color: #a9d3ab !important;
    }
  }

  .loading {
    margin-bottom: 4px;
    margin-left: 5px;
  }
}

.dropdown-filter {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 5px !important;
    margin-left: 10px;
    font-size: 12px;
  }
  &.sm {
    width: fit-content;

    .dropdown-filter-icon {
      margin-left: 10px;
    }
  }

  .dropdown-filter-component {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--wgtLightGray);
    border: 1px solid #ced4da;
    cursor: pointer;

    p {
      font-style: italic;
      color: var(--wgtGray);
      padding: 0;
      margin: 0;
    }

    &.selected {
      background-color: var(--wgtWhite);
      p {
        color: var(--wgtInputText);
        font-style: normal;
        white-space: nowrap;
      }
    }

    .dropdown-filter-icon {
      color: #4f5455;
    }
  }
  .dropdown-filter-menu {
    border: 1px solid var(--wgtGray);
    padding: 10px;
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: 1;
    background: #fff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    .dropdown-filter-input {
      align-items: center;
      justify-content: center;
      display: flex;
      border: 1px solid var(--wgtGray);
      margin-bottom: 10px;
      input {
        flex: 1;
        border: none;
        font-size: 13px;
        padding: 8px;
      }
      .dropdown-close-icon {
        cursor: pointer;
        margin: 0 10px;
      }
    }
    ul {
      padding: 0 10px;
      margin: 0;
      li {
        font-size: 13px;
        list-style: none;
        cursor: pointer;
        margin-bottom: 5px;
        white-space: nowrap;
      }
    }
  }
}

.invoice-summary-card {
  box-shadow: var(--wgtBoxShadow);
  padding: 20px;
  margin-right: 0 !important;
  margin-left: 0 !important;

  .invoice-summary-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--wgtTextColor);

    &.red {
      color: var(--wgtRed);

      .invoice-summary-item-label {
        color: var(--wgtRed) !important;
      }
    }

    .invoice-summary-item-left {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      .invoice-summary-item-icon {
        cursor: pointer;
        color: var(--wgtGray) !important;
        margin-right: 5px;
        position: relative;
        top: -3px;
      }

      span {
        margin-right: 10px;
      }

      .invoice-summary-item-label {
        text-align: right;
        font-size: 12px !important;
        font-style: italic;
        margin-top: -5px;
        color: var(--wgtGray);
        &.highlight {
          color: var(--wgtTextColor);
          font-style: normal !important;
        }
      }

      .invoice-summary-item-number {
        &.highlight {
          font-weight: 900;
          font-family: 'noto_sansbold' !important;
        }
      }
    }
  }

  .invoice-summary-card-total-holder {
    text-align: right;
    .highlight {
      color: var(--primaryColor);
      font-size: 25px !important;
    }
    .red {
      .invoice-summary-item-number {
        font-family: 'noto_sansbold' !important;
      }
      .invoice-summary-item-label {
        font-style: normal;
      }
    }
  }
}

.login-screen {
  background-image: linear-gradient(
    180deg,
    rgba(49, 193, 253, 0.94) 0%,
    rgba(196, 196, 196, 0) 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .overlay {
    background-image: url('../../assets/logo_bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }
  .form-holder {
    z-index: 1;
    flex: 1;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .image-logo img {
      width: 375px;
    }
    form {
      width: 400px;
      background-color: white;
      border-radius: 10px;
      margin-top: 20px;
      padding: 40px 30px;
      box-shadow: 0px 4px 4px rgba(96, 96, 96, 0.25);
      border: none;
      label {
        font-size: 14px;
        margin-left: 10px;
        color: #373c3e;
      }

      input {
        height: 49px;
        &::placeholder {
          font-style: italic;
          font-size: 14px;
          color: #929a9c;
        }
      }
      .forgot-link {
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #373c3e;
        float: right;
        margin-top: 5px;
        margin-bottom: 20px;
      }

      button {
        height: 35px;
        font-size: 14px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
        border: solid 1px rgba(55, 60, 62, 0.2);
        background-color: #8aca26;
      }
      p {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}

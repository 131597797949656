.dropdown-popover-select {
  select {
    font-family: 'noto_sansregular' !important;
    font-size: 14px !important;
    height: 36px;
  }

  span {
    font-size: 14px !important;
    color: var(--wgtTextColor);
    width: 154px;
    text-align: right;
    margin-right: 10px;
  }

  .is-invalid {
    button {
      border: 1px solid var(--wgtRed);
    }
  }
}

.dropdown-popover-dialog {
  max-width: 960px !important;
  padding: 1rem 1rem 0 1rem;
  flex-direction: column !important;
  display: flex !important;

  label,
  p {
    font-size: 14px !important;
  }

  .popover-titles {
    color: var(--wgtPrimary);
  }

  .popover-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    > div {
      border-right: 1px solid #c3c3c3;

      &:last-child {
        border-right: unset;
      }
    }

    input[type='checkbox'] {
      display: none;
      ~ span {
        width: 13px;
        height: 13px;
        border: 1px solid var(--wgtTextColor);
        border-radius: 0.15rem;
        margin-right: 0.5rem;

        &.disabled {
          border-color: #ccc;
        }
      }
      &:checked ~ span {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
        background-color: #0074d9;
        border-color: #0074d9;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80% 80%;
      }
    }
  }
}

.dropdown-popover-sub {
  .popover {
    max-width: 720px;

    label,
    p {
      font-size: 14px !important;
      margin-bottom: 0;
    }

    .popover-titles {
      margin-bottom: 0.5rem;
      margin-top: 0;
      color: var(--wgtPrimary);
    }

    .variation-value-checkbox:checked + label {
      text-decoration: underline;
      color: var(--linkColor);
    }

    .column-variation {
      max-width: 240px;
      width: 240px;
    }
  }
}

@import '../../../../assets/scss/variables';

.update-product-screen {
  .page-ids-route {
    border: 1px solid $gray-09 !important;
    background-color: $gray-03;
    width: 297px;
    button {
      width: 165px;

      svg {
        fill: $gray-08 !important;
      }
    }
    .input-group {
      .input-group-prepend {
        height: 100%;
        .input-group-text {
          background-color: $gray-01;
          p {
            color: $white-01;
          }

          &.page-id-index {
            background-color: $blue-02;
          }
        }
      }
    }
  }

  .max-width-100 {
    max-width: 100% !important;
  }

  .page-id-button-content {
    white-space: nowrap;
  }

  .input-box-field {
    border: 1px solid #d8d7d7;
    border-radius: 0.25rem;
    .simple-textarea,
    .simple-input {
      display: flex;
      flex-direction: column;
      align-items: center;

      label {
        background-color: #dedede;
        margin-left: 0;
        width: 100%;
        padding: 5px 0 5px 10px;
        margin-bottom: 0;
        color: #727a7d !important;
      }
      textarea,
      input {
        padding: 0 0 0 10px !important;
        border: none;
        font-size: 16px;
      }
    }
  }

  .card-group {
    border-color: #d8d7d7 !important;
    .card {
      .card-header {
        padding: 5px 0 5px 10px !important;
        font-size: 14px !important;
        background-color: #dedede !important;
        color: #727a7d !important;
      }
    }

    > .card:not(:last-child) {
      border-right: 0;
    }
  }

  .font-regular {
    font-size: 1rem !important;
    margin-bottom: 0;
  }

  .text-trace {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }

  .reports-content-list > table {
    width: 100%;
  }

  .pos-actions {
    padding: 0.5rem;

    p {
      color: white;
    }
    button.button.primary {
      background-color: white !important;
    }
  }

  .common-attributes {
    border-bottom: 0 !important;
  }

  .shape-container {
    @media (max-width: 991px) {
      border: 1px solid #d8d7d7;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
      border-radius: 0.25rem;

      .shape-title {
        background-color: #dedede !important;
        color: #727a7d !important;
        p {
          font-size: 14px !important;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .sku-form {
    @media (max-width: 1199px) {
      width: 330px;
    }
  }

  .search-form-sku {
    div[class$='-placeholder'] {
      font-size: 11px !important;
    }
  }
}

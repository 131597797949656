.navbar-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div {
    border-radius: 3px;
    overflow: hidden;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    img {
      width: 40px;
      height: auto;
      float: right;
    }
  }
  span {
    margin-top: 1px;
  }
}

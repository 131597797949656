.work-order-type-list {
  .head {
    p {
      font-size: 14px !important;
      color: #92999c;
    }
  }

  .body {
    p {
      font-size: 15px !important;
      margin-bottom: 0 !important;
      color: #373c3e !important;
      display: inline-block;
    }
    .btn-icon-edit {
      height: 28px;
      svg {
        color: var(--wgtDarkGray);
      }
    }
    .icon-toggle-width {
      width: 40px;
    }
    .selectable {
      background-color: #488bb1;
      p {
        color: #ffffff !important;
      }
    }
    .no-selectable {
      background-color: #92999c;
      p {
        color: #ffffff !important;
      }
    }
  }
}

.work-order-type-modal .close span {
  font-size: 22px !important;
}

.avatar-button {
  max-width: 250px;
  display: flex;
  border: 0.001em solid;
  border-color: var(--wgtGray) !important;
  border-radius: 15px;
  padding: 10px 20px;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  border-image: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.164) !important;
  }

  div {
    margin-right: 10px;
  }
}

.storage-price-item {
  background-color: #f9f9f9;
  padding: 20px;
  border: 2px solid #98e0ff;

  .price-container {
    border: 2px solid #3c8eb1;
    padding: 0 0 10px 10px;
    font-size: 12px;
  }

  svg {
    color: #e4e8ef;

    &.selected {
      color: #2a94f4;
    }
  }

  .price-icon-holder {
    .price-icon-content {
      width: 50px;
      height: 150px;
      border: 5px solid #89aeff;

      .price-icon-content-holder {
        width: 100%;
        height: 30%;

        .price-icon-content-line {
          width: 5px;
          background-color: #89aeff;
          height: 100%;
        }

        &.up {
          border-top: 5px solid #89aeff;
        }
        &.down {
          border-bottom: 5px solid #89aeff;
        }
      }
    }
  }
}

.navbar-categories-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;

  .navbar-categories-container-item {
    width: 100%;
    margin-bottom: 20px;

    button {
      font-weight: 700;
      font-size: 14px;

      width: 180px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: none;

      &.active {
        color: var(--wgtPrimary);
      }
    }
    .navbar-categories-title {
      font-weight: 700;
      font-size: 13px !important;
      color: #373c3e;
      margin-bottom: 10px;
    }
  }
}

.profile-account-page {
  margin-top: 50px;

  .profile-security-menu {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      margin-top: 5px;

      a {
        color: #373c3e;
      }
    }
  }
}

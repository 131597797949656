.parcel-type-selector {
  .parcel-type-holder {
    display: flex;
    flex-direction: row;
    .parcel-type-item {
      cursor: pointer;
      margin: 10px;
      padding: 10px 30px;
      border: 1px solid var(--wgtPrimary);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
      font-size: 12px !important;
      .parcel-type-title {
        color: var(--wgtPrimary);
        font-size: 12px !important;
      }
      .parcel-type-description {
        color: var(--wgtDarkGray);
        font-size: 10px !important;
      }

      &.active {
        background-color: var(--wgtPrimary);
        .parcel-type-title {
          color: #fff;
        }
        .parcel-type-description {
          color: #fff;
        }
      }
      &.disabled {
        cursor: not-allowed;
      }

      &:first-child {
        margin-left: 0 !important;
      }
    }
  }
}

.table-striped .table-stripe-row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.pos-actions {
  background-color: var(--wgtPrimary);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  span {
    color: #fff;
    margin-bottom: 10px;
  }
  .pos-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    button {
      background-color: #fff;
      border: 0;
      border-radius: 5px;
      flex: 0.48;
      font-size: 12px;
      color: var(--wgtPrimary);
      padding: 5px;
      justify-content: center;
    }
  }
}

#result-search {
  .header {
    font-size: 14px !important;
    color: var(--wgtDarkGray) !important;
    div:first-child {
      width: 132px;
    }
    div:last-child {
      text-align: right;
    }
  }

  .item {
    > div {
      align-items: center !important;
      display: flex !important;
      span {
        font-size: 14px !important;
        color: var(--wgtTextColor);
      }
      &:nth-last-of-type(2) {
        justify-content: flex-end !important;
      }
    }
  }

  .icon-toggle {
    height: 28px;
    margin-left: 10px;
    svg {
      color: var(--wgtDarkGray);
      stroke-width: 1 !important;
    }
  }

  .image-media {
    width: 35px;
    height: 35px;
    margin-left: 10px;
  }
}

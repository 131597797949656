.new-product-form {
  background-color: #f4f6f8;
  padding: 0 40px;
}

.header-form {
  background-color: #f4f6f8;
  margin: 0 !important;

  .img-holder {
    width: 60px;
    height: 60px;
    background-size: cover;
  }
}

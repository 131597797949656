@import '../../../assets/scss/scrollbar';

#hr {
  p {
    font-size: 14px !important;
    margin-bottom: 0 !important;
    &.subtitle {
      font-size: 16px !important;
      font-weight: 700;
      margin-bottom: 0.75rem !important;
    }
  }

  .security {
    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      font-size: 14px !important;
      li {
        margin-bottom: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .contacts {
    .nav-item-contact {
      a {
        padding-top: 0;
      }
    }
    .no-line-bottom {
      margin-bottom: 0 !important;
    }
    .height-default {
      height: 37px;
    }
    .nav-link {
      cursor: pointer;
    }
  }

  .add-employee-form {
    > div > div {
      margin-bottom: 1rem;
    }

    .simple-dropdown {
      label {
        margin-bottom: 5px;
      }

      > div > div {
        min-height: 36px;
        border-color: var(--wgtInputBorder) !important;
      }
    }

    .input-office-phone {
      label {
        margin-left: 10px;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .results {
    .image-media {
      width: 35px;
      height: 35px;
    }
  }
}

.hr-modal {
  @include scrollbar;

  .content-scroll {
    right: 0.5rem;
    left: 1.5rem;
    overflow-y: scroll;
    position: absolute;
    height: 100%;
  }

  .tab-content {
    .tab-pane {
      height: 100%;
    }
  }

  .text-extended {
    background-color: #ffffff;
    width: 100%;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      width: unset;
      p {
        background-color: #ffffff;
        position: relative;
        z-index: 999;
        padding-right: 0.5rem;
        &:first-child {
          padding-top: 0.5rem;
        }
        &:last-child {
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .hr-employee-drag-drop {
    h2 {
      font-size: 20px !important;
    }
  }

  .photo-preview {
    border: 1px dashed #9b9c9c;
    img {
      width: 30%;
    }
  }
}

.popover-jobtitle,
.popover-department {
  p,
  label {
    font-family: 'noto_sansregular' !important;
    font-size: 14px !important;
  }

  .popover {
    min-width: 365px;
  }

  .title {
    padding-top: 0.5rem;
    color: var(--wgtPrimary);
    font-size: 15px !important;
  }

  .list-checkbox > div > label > input[type='checkbox']:first-child {
    width: 14px !important;
    height: 14px !important;
  }

  input[type='checkbox'] {
    width: 15px;
    height: 15px;
    margin-top: 0.1rem !important;
  }

  @include scrollbar;

  .popover-scroll {
    max-height: 250px;
    overflow-y: auto;
  }

  .is-invalid {
    border-color: #dc3545 !important;
  }

  .btn-icon-more-items {
    height: 16px;
    margin-top: 2px;
    color: #212529;
    &:hover {
      background-color: #fff !important;
    }
  }

  .ml-6 {
    margin-left: 3.25rem !important;
  }

  .ml-7 {
    margin-left: 3.5rem !important;
  }

  .ml-8 {
    margin-left: 4rem !important;
  }

  .ml-9 {
    margin-left: 4.25rem !important;
  }

  .ml-10 {
    margin-left: 4.5rem !important;
  }
}

.add-job-title-modal {
  .modal-title {
    font-size: 22px !important;
  }
  button.close {
    padding-top: 1rem;
    padding-bottom: 0;
    span {
      font-size: 22px !important;
    }
  }
}

.popover-office-category {
  p,
  label {
    font-family: 'noto_sansregular' !important;
    font-size: 12px !important;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .popover {
    max-width: 550px;
  }
  .title {
    padding-top: 0.5rem;
    color: var(--wgtPrimary);
    font-size: 15px !important;
  }
}

.request-to-join {
  button.button {
    height: 36px !important;
  }
  .company-title {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }

  .simple-textarea label {
    font-size: 14px;
  }

  .label-password {
    display: inline-block;
    font-size: 11px;
    width: 102px;
    margin-bottom: 0;
  }

  .btn-delete-width {
    width: 60px;
  }
}

@font-face {
  font-family: 'noto_sansbold';
  src: url('../fonts/notosans-bold-webfont.woff2') format('woff2'),
    url('../fonts/notosans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: 600;
}

@font-face {
  font-family: 'noto_sansbold_italic';
  src: url('../fonts/notosans-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/notosans-bolditalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'noto_sansitalic';
  src: url('../fonts/notosans-italic-webfont.woff2') format('woff2'),
    url('../fonts/notosans-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'noto_sansregular';
  src: url('../fonts/notosans-regular-webfont.woff2') format('woff2'),
    url('../fonts/notosans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'noto_sansregular';
  src: url('../fonts/notosans-bold-webfont.woff2') format('woff2'),
    url('../fonts/notosans-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif.woff2') format('woff2'),
    url('../fonts/NotoSerif.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif-BoldItalic.woff2') format('woff2'),
    url('../fonts/NotoSerif-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif-Bold.woff2') format('woff2'),
    url('../fonts/NotoSerif-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif-Italic.woff2') format('woff2'),
    url('../fonts/NotoSerif-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

.navbar-divider {
  width: 1px;
  height: 40px;
  background-color: var(--wgtWhite);
  margin: 0 20px;

  @media screen and (max-width: 991px) {
    display: none;
  }
}

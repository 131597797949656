#modal-payment {
  .modal-content {
    border: none;

    .modal-header {
      background-color: #e5e5e5;

      .modal-title {
        font-weight: bold !important;
        color: var(--wgtTextColor) !important;
        font-family: 'noto_sansregular' !important;
        font-size: 24px !important;
        line-height: 28px !important;
      }

      .sub-title {
        font-size: 14px !important;
        color: var(--wgtTextColor) !important;
      }
    }

    .modal-body {
      .currency-input {
        label {
          margin-left: 0 !important;
        }
        .flag-currency {
          height: 14px;
        }
      }

      .upload-receipts {
        span {
          font-size: 14px !important;
        }
        .button-box {
          border-radius: 0.25rem !important;
          border: 1px dashed var(--wgtInputText);
          height: 80px;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          position: relative;

          svg {
            stroke-width: 0.5;
            z-index: 3;
            color: var(--mutedColor) !important;
          }

          .badge-upload {
            position: absolute;
            background-color: #e17b22;
            border-radius: 50%;
            height: 23px;
            width: 23px;
            font-size: 14px;
            text-align: center;
            color: #fff;
            right: 25px;
            bottom: 47px;
            padding-top: 2px;
            z-index: 1;
          }
        }
      }

      .plan-pay-calculate-checkbox {
        input[type='checkbox'] {
          height: 16px;
          width: 16px;
          border-color: var(--wgtDarkGray);
        }
        label {
          margin-bottom: 0;
          font-size: 14px;
          color: var(--wgtDarkGray);
        }
      }

      .payment-resume-total {
        .label {
          font-size: 14px !important;
          color: var(--wgtTextColor) !important;
          display: block;
          margin-bottom: 10px;
        }

        .value {
          font-size: 20px !important;
          display: block;
          color: var(--wgtTextColor) !important;
        }

        .total .value {
          color: var(--switchButtonSelected) !important;
          font-weight: bold !important;
        }

        .total-due .value {
          color: var(--wgtRed) !important;
        }
        .planned {
          .label {
            color: var(--mutedColor) !important;
            font-style: italic;
          }
        }
      }

      .label-plan-next-payment,
      .label-calculate-interest {
        font-size: 12px;
        margin-bottom: 0;
        color: var(--wgtDarkGray);
      }

      .checkbox-options-open {
        position: unset;
        margin-top: 0;
        margin-left: 0;
      }

      .section-plan-next-payment {
        .react-datepicker-wrapper {
          width: 100%;
        }
        .btn-icon-remove {
          color: var(--wgtDarkGray);
          &:hover {
            background-color: unset;
            color: black;
          }
        }
      }
    }

    .modal-footer {
      .label-password {
        display: inline-block;
        font-size: 11px;
        width: 102px;
      }
    }
  }
}

.payment-type-checkbox {
  input[type='checkbox'] {
    height: 16px;
    width: 16px;
    border-color: var(--wgtDarkGray);

    position: unset;
    margin-top: 0.3rem;
    margin-left: 0;
  }
  label {
    margin-bottom: 0;
    font-size: 14px;
    color: #212529;
  }
  .btn-payment-type-more {
    width: 17px;
    &:hover {
      background-color: unset !important;
    }
  }
}

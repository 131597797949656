.coming-soon-modal {
  .modal-dialog {
    max-width: 610px !important;
    .modal-content {
      background-color: #dce8f7 !important;
      padding: 30px;

      .menu-icon {
        width: 150px;
        color: #0f56b3;
        padding: 10px;
        font-weight: bold;
        margin-bottom: 70px;
        border-radius: 5px;
        font-size: 13px;
      }
      span {
        color: #0f56b3 !important;
      }
    }
  }
}

.wgt-alert-message {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  background-color: rgb(244, 246, 248) !important;
  color: var(--wgtTextColor);
  padding: 1rem !important;
  font-size: 14px;

  button.button {
    color: var(--wgtPrimary);
    box-shadow: none;
    padding: 0;
    margin: 0;
    font-size: 1rem !important;
    font-weight: 400 !important;
    font-family: 'noto_sansregular' !important;
    background-color: transparent !important;
    height: 0 !important;
  }
}

@import '../../../../../../assets/scss/scrollbar';

.thumbnails-list {
  @include scrollbar(rgb(255, 255, 255));
  .overflow-x-auto {
    overflow-x: auto;
  }

  .card {
    border-radius: 0 !important;
  }

  .card-block {
    border: none;
    position: relative;
  }

  .active {
    box-shadow: inset 0px 0px 0px 10px #0074a0;
  }

  .card-img-top {
    max-height: 90px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    max-width: 125px;
    object-fit: scale-down;
  }

  .remove-media-button {
    background-color: #c0c0c0;
    position: absolute;
    padding: 1px;
    border-radius: 10px;
    right: 5%;
    top: 5%;
  }
}

.calculator-component {
  border: 1px solid;
  padding: 20px;
  border-radius: 5px;
  width: 260px;
  .calculator-titleHolder {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .calculator-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 0 1 60%;

    .calculator-result {
      align-self: stretch;
      flex: 0 1 80%;
      padding: 5px;
      input {
        background-color: var(--wgtLightGray);
        width: 100%;
        border: 1px solid var(--wgtGray);
        height: 30px;
        border-radius: 5px;
        text-align: right;
      }
    }

    .calculator-button-wrapper {
      flex: 0 1 20%;
      cursor: pointer;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;

      &.operation {
        span {
          height: 30px;
          border-radius: 5px;
          background-color: var(--wgtLightGray);
          color: var(--wgtInputText);
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.equal {
        flex: 0 1 40%;
        span {
          padding: 0 10px;
          background-color: var(--wgtLightGray);

          justify-content: flex-start;
        }
      }
    }
  }
}

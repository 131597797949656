.shape-details {
  background-color: #f4f6f8;
  padding: 10px;

  .shape-image-card {
    background-color: #fff;
    margin: 0 !important;
    margin-bottom: 20px !important;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
  }
}

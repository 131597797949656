.template-shapes {
  margin-top: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  .shapes-list {
    margin-top: 20px;

    > label {
      margin-top: 10px;
    }
    > div {
      margin-right: 20px;
      width: 100%;
    }
  }
  .template-shapes-list {
    margin-top: 20px;

    .template-shapes-list-item {
      border-bottom: 1px solid var(--wgtGray);
      padding: 20px;
      display: flex;
      align-items: center;
      > div {
        height: 50px;
        width: 50px;
        background-size: cover;
        border-radius: 100%;
        border: 3px solid var(--wgtPrimary);
        margin-right: 20px;
      }
      > span {
        flex: 1;
      }
    }
  }
}

.top-modal {
  background-color: #123753;
  color: #fff;

  .top-modal-content {
    padding: 40px;

    p {
      text-align: center;
      font-size: 16px !important;
      font-style: italic;
      line-height: 25px !important;
    }
    button {
      margin: 0 10px;
    }

    small {
      color: #929a9c;
    }
  }
}

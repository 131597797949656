.margins-summary-card {
  box-shadow: var(--wgtBoxShadow);
  padding: 20px;
  h2 {
    margin-bottom: 20px;
  }
  div {
    .margins-summary-card-amount {
      font-family: 'noto_sansbold' !important;
      font-size: 20px !important;
      display: block;
    }
    margin-bottom: 30px;
  }
}

.dropzone {
  cursor: pointer;
  min-height: 160px;
  border: 1px dashed #9b9c9c;
  background: white;
  padding: 20px 20px;
  display: flex;

  h2 {
    color: #9b9c9c !important;
    font-style: italic;
    font-size: 20px;
  }

  .dropzone-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    span {
      margin: 15px 0;
      color: #9b9c9c !important;
    }
  }
}

.file-list-item {
  font-size: 13px;
  margin: 10px 0;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d2d3d3;
  span {
    color: var(--wgtPrimary);
  }
}

.expanded-dropdown-color {
  span {
    margin-bottom: 6px;
    display: block;
  }
  .color-container {
    height: 70px;
    width: 70px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  label {
    color: var(--wgtTextColor);
    font-size: 12px !important;
  }

  .active {
    label {
      font-family: 'noto_sansbold';
    }
  }
}

.expanded-dropdown-image {
  div {
    cursor: pointer;
  }
  span {
    margin-bottom: 6px;
    display: block;
  }

  label {
    color: var(--wgtTextColor);
    font-size: 12px !important;
  }

  .active {
    label {
      font-family: 'noto_sansbold';
    }
  }
}
.expanded-dropdown-text {
  span {
    background-color: var(--switchButtonSelected);
    color: var(--wgtWhite);
    margin: 0 7px;
    border-radius: 10px;
    padding: 0 30px;
    vertical-align: middle;
    text-decoration: none;
    align-items: center;
    display: flex;
    padding: 5px 15px;
  }
  label {
    cursor: pointer;
    border: 1px solid var(--switchButtonSelected);
    color: var(--switchButtonSelected);
    margin: 0 7px;
    border-radius: 10px;
    padding: 3px 15px;
    vertical-align: middle;
    text-decoration: none;
    align-items: center;
    display: flex;
    font-size: 14px;

    &.active {
      background-color: var(--switchButtonSelected);
      color: #fff;
    }

    input {
      display: none;
    }
  }
}

.basic-multi-select.is-invalid > div:first-child {
  border: 1px solid var(--wgtRed);
}

.simple-input.template-input-component {
  display: flex;
  align-items: center;
  margin-bottom: unset;
  justify-content: flex-end;
  flex-direction: row !important;
  margin-right: 0;
  margin-bottom: 1rem;
  label {
    margin-bottom: 0;
    font-size: 12px;
    width: 6.5rem;
    text-align: right;
    margin-left: 0;
    margin-right: 1rem;
  }

  input {
    flex: 1;
  }
}

.expanded-simple-dropdown.template-dropdown-list-component {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: flex-start;
  flex-direction: row !important;
  margin-right: 0;

  > div {
    flex: 1;
  }

  span {
    margin-bottom: 0;
    font-size: 12px !important;
    width: 6.5rem;
    text-align: right;
    margin-left: 0;
    margin-right: 1rem;
  }

  select {
    border-color: var(--wgtInputBorder) !important;
  }
}

.storage-row {
  height: 50px;
  border: 3px solid #3c8eb1;
  width: 100%;
  overflow: hidden;
  .storage-row-content {
    height: 50px;
    width: 50;
    background: #3c8eb1;
  }
}

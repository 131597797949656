@import 'fonts';

#wgt-report {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span {
    font-family: 'Open Sans' !important;
    margin: 0;
    padding: 0;
    color: #000000 !important;
    line-height: normal !important;
  }

  h1 {
    font-weight: 700 !important;
    font-size: 32px !important;

    span {
      font-weight: 300 !important;
      font-size: 32px !important;
    }
  }

  h2 {
    &.title-data {
      font-size: 1rem !important;
      color: #92999c !important;
    }
  }

  p {
    font-size: 14px !important;
    &.font-small {
      font-size: 12px !important;
    }
    &.font-large {
      font-size: 16px !important;
    }
    &.font-xlarge {
      font-size: 20px !important;
    }
  }

  strong {
    font-weight: 600;
  }

  .img-logo {
    width: 120px;
    border: 0 !important;
  }

  .title-text-muted {
    color: #6c757d !important;
    font-weight: 700 !important;
    margin-bottom: 0.5rem;
    text-transform: uppercase !important;
    font-size: 12px !important;
  }

  .table {
    &.default-report {
      td,
      th {
        padding: 0.5rem 0 0.5rem 0;
      }
      thead th {
        border-top: unset;
        font-weight: 700 !important;
        border-bottom: 1px solid #dee2e6;
        font-size: 12px;
        text-transform: uppercase !important;
        color: #6c757d !important;
      }

      tbody td {
        border-top: unset;
      }
    }
  }

  .w-10 {
    width: 10%;
  }

  .w-20 {
    width: 20%;
  }

  .color-gray {
    color: #373c3e !important;
  }

  .color-muted {
    color: #6c757d !important;
  }

  .color-primary {
    color: #0074a0 !important;
  }

  .color-danger {
    color: #d31a1a !important;
  }

  .border-color-primary {
    border-color: #0074a0 !important;
    border-width: 2px !important;
  }

  .box-report-yellow-blue {
    .header {
      background-color: #ffb041;
      color: #ffffff;
      font-weight: 600;
      padding: 0.25rem 1rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    .body {
      border-right: 1px solid #00b0fc;
      border-left: 1px solid #ffb041;
      border-bottom: 1px solid #ffb041;
      font-size: 12px !important;

      .row:nth-child(even) {
        border-bottom: 1px solid #ffb041;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }

      .row:nth-child(odd) {
        border-bottom: 1px solid #00b0fc;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
      .row:last-child {
        border-bottom: unset;
      }
    }
  }

  .box-report-blue {
    .header {
      background-color: #0074a0;
      color: #ffffff;
      font-weight: 600;
      padding: 0.25rem 1rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .body {
      border-left: 1px solid #0074a0;
      border-right: 1px solid #0074a0;
      font-size: 12px !important;
      .row {
        border-bottom: 1px solid #0074a0;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
  }

  .box-report-blue-mixed {
    .header {
      background-color: #0074a0;
      color: #ffffff;
      font-weight: 600;
      padding: 0.25rem 1rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .body {
      border-left: 1px solid #0074a0;
      border-right: 1px solid #0074a0;
      border-bottom: 1px solid #0074a0;
      font-size: 12px !important;

      .row {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
      }
      > .row {
        &:nth-child(even) {
          border-bottom: 1px solid #0074a0;
        }

        &:nth-child(odd) {
          border-bottom: 1px solid #00b0fc;
        }
        &:last-child {
          border-bottom: unset;
        }
        .row {
          &:nth-child(even) {
            border-bottom: 1px solid #0074a0;
          }

          &:nth-child(odd) {
            border-bottom: 1px solid #00b0fc;
          }
          &:last-child {
            border-bottom: unset;
          }
        }
      }
    }
  }

  #aml-statement {
    p,
    strong,
    h1 {
      color: #373c3e !important;

      &.color-primary {
        color: #0074a0 !important;
      }
    }
    .body > .row {
      padding-top: 4px !important;

      .col-md-2:last-child {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        max-width: 100% !important;
      }
    }

    .box-totals {
      div {
        margin-bottom: 0.25rem;
        font-size: 16px !important;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .simple-invoice-logo {
    width: 120px;
  }
}

.table-component {
  overflow-x: auto;
  .table-component-image-holder {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 50px;
    }
  }
  tr {
    th {
      font-size: 15px;
      line-height: 9px;
      padding-top: 30px;
      color: var(--wgtDarkGray);
      font-weight: 400;
    }
    td {
      vertical-align: middle;
      padding-bottom: 2px;
      padding-top: 2px;
      color: #2d2d2d;
      svg {
        cursor: pointer;
        margin-top: -4px;
        color: var(--wgtGray);
      }

      &.edit-col {
        width: 120px !important;
        text-align: right;
        .edit-holder {
          display: none;
          &.active {
            display: block;
          }
        }
      }
    }
    span,
    a {
      font-size: 13px !important;
      line-height: 9px;
    }
  }
  tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #dee2e6;
  }
}

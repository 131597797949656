@mixin img-thumbnail {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  height: auto;
}

.img-thumbnail-regular {
  max-width: 100px;
  @include img-thumbnail;
}

.img-thumbnail-small {
  max-width: 50px;
  @include img-thumbnail;
}

@import 'fonts';
@import 'variables';
@import 'typography';
@import 'toastify';
@import 'button';
@import 'scrollbar';
@import 'modal';
@import 'image';

html,
body {
  height: 100% !important;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column !important;
}

body {
  font-family: 'noto_sansregular' !important;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
a {
  font-family: 'noto_sansregular' !important;
  font-size: 14px !important;
}

span {
  &.text-link {
    color: var(--linkColor) !important;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &.text-muted {
    color: var(--mutedColor) !important;
  }
}

.switch-buttons {
  button {
    background-image: none !important;
    margin: 0 7px;
    border-radius: 10px;
    padding: 0 30px;
    &.active {
      background-color: var(--switchButtonSelected);
      color: var(--wgtWhite);
    }
  }
}

.full-content {
  margin-bottom: 75px;
  display: flex;

  > div:first-child {
    flex: 1;
  }
}

.opacity-0 {
  opacity: 0;
  height: 0;
}
.nav-tabs {
  .nav-item {
    cursor: pointer;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

a.text-decoration-none {
  text-decoration: none;
}

.color-wgt-primary {
  color: var(--wgtPrimary);
}

.bg-gray {
  background-color: #f9f9f9;
}

.field-container {
  > div {
    display: flex;
    flex: 1;
    margin-right: 15px;
    &.simple-input {
      flex-direction: column;
    }
  }
  &.field-container-bordered-error {
    &.field-container-invalid {
      border: 1px solid #d31a1a;
      background: rgba(211, 26, 26, 0.25);
      border-radius: 5px;
      box-sizing: border-box;
      padding: 5px;
      margin: 5px 0;

      > div {
        flex: 0.8;
      }
      .error-message {
        flex: 0.3;
      }
    }
  }
  // 'field-container-bordered-error': borderedError,
  // 'field-container-invalid': getProp(errors, fieldToManage),
}

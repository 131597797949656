.learn-to-use {
  color: #000000;
  font-weight: normal;
  width: 100% !important;
  .bullet {
    font-size: 26px;
    line-height: 0.8;
    padding-left: 5px;
    padding-right: 5px;
    color: #92999c;
  }

  .section-title {
    background-color: #e5e5e5;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    & > div {
      width: 75%;
    }
  }

  .btn-learn-to-use-default {
    border: 1px solid #0074a0;
    background: #f8f8f8;
    color: #0074a0;
    font-family: Noto Serif;
    font-size: 14px;
    padding: 0.75rem 1rem;

    &:hover,
    &:visited,
    &:focus {
      border: 1px solid #0074a0;
      background: #ffc694;
      color: #0074a0;
      box-shadow: unset;
    }
  }

  .btn-learn-to-use-secondary {
    border: 1px solid #0074a0;
    background: #f8f8f8;
    color: #0074a0;
    font-family: Noto Serif;
    font-size: 14px;
    padding: 1.25rem 1rem;

    &:hover,
    &:visited,
    &:focus {
      border: 1px solid #0074a0;
      background: #00f9db;
      color: #0074a0;
      box-shadow: unset;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    .button-section {
      width: 90%;
      button {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.trace-documents-sidesheet {
    .trace-documents-sidesheet-document-header {
        .trace-documents-sidesheet-document-header-img {
            width: 128px;
            height: 128px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .trace-documents-sidesheet-document-page-ids {
        .img {
            width: 128px;
            height: 128px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

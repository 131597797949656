.template-put {
  width: 100%;

  display: flex;

  .template-put-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-right: -15px;
    margin-left: -15px;

    .template-put-sidebar {
      background-color: #e5e5e5;
      width: 30vw;

      .template-put-sidebar-tab {
        width: 100%;
        .template-put-sidebar-tab-header {
          background-color: #fff;
          flex: 1;
          margin-bottom: 15px;
          padding-left: -15px;
          padding-right: -15px;
          .template-put-sidebar-tab-title {
            border: 1px solid var(--wgtPrimary);
            padding: 5px;
          }

          .nav-tabs {
            .nav-item {
              padding: 5px;
              flex: 0.5;
              border: 1px solid var(--wgtPrimary) !important;

              .nav-link {
                border: none !important;
                color: var(--wgtGray);
                &.active {
                  color: var(--wgtPrimary);
                }
              }
            }
          }
        }
      }

      .component-values-list {
        height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;
      }

      .template-form-edit {
        background-color: #000;
      }
      .template-put-sidebar-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .template-put-sidebar-box {
          display: flex;
          flex-direction: column;
          width: 50%;

          min-width: 115px;
          padding: 5px 15px;
          > div {
            height: 80px;
            border-radius: 5px;
            align-items: center;
            background-color: white;
            cursor: move;
            display: flex;
            justify-content: center;
            text-align: center;
            opacity: 1;
            padding: 10px 5px;
            font-size: 12px;
            &.transparent {
              opacity: 0.4;
            }
          }
        }
      }
    }

    .template-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      h3 {
        margin-bottom: 21px;
      }
      button {
        &.controller {
          border: none;
          font-size: 16px;
          background: none;
          color: var(--wgtPrimary);
          position: relative;
          top: -5px;
          margin-left: 10px;
        }
      }
    }
    .template-fields-list {
      border: 1px dashed gray;
      color: #000;
      float: left;
      font-size: 1rem;
      line-height: normal;
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
      padding: 1rem;
      text-align: center;
      width: 100%;
      min-height: 120px;
      display: flex;
      flex-wrap: wrap;
    }
    .template-put-content {
      flex: 1;
      padding: 15px;

      .template-put-content-field-item {
        cursor: pointer;
        border: 1px solid var(--wgtGray);
        border-radius: 10px;
        padding: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 300px;
        text-align: left;
        font-size: 12px;
        max-height: 200px;

        > b {
          font-size: 18px;
          margin-bottom: 10px;
        }

        &.selected {
          border-color: var(--wgtPrimary);
          background-color: var(--wgtPrimary);
          color: #fff;
          > b {
            color: #fff !important;
          }
        }
      }
    }
  }
}

.dependency-title-holder {
  width: 100%;
  background-color: var(--wgtPrimary) !important;

  text-align: left !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #fff !important;

  &:active,
  &:focus {
    border: 0px;
  }
  h2 {
    color: #fff !important;
  }
}

.field-value-dependency-holder {
  border-bottom: 1px solid var(--wgtPrimary);
  padding: 20px;

  h2 {
    margin-bottom: 20px;
  }
}

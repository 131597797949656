.property-value-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    margin-right: 20px;
  }
}

.values-form {
  .btn-components-col {
    label {
      margin-left: 10px;
      font-size: 14px;
      margin-bottom: 5px;
      display: block;
    }

    button {
      height: 36px;
      white-space: nowrap;
    }

    .button-color > .color {
      font-size: 16px !important;
      margin-left: 0.5rem;
    }
  }
  .abbreviations-col label {
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .color-popover {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
  .color-popover-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .resume-values {
    height: 80px !important;
    p {
      font-size: 14px !important;
      text-align: center !important;
      &.highlight {
        color: var(--wgtPrimary) !important;
      }
    }
  }
}

.propery-value-modal .close span {
  font-size: 22px !important;
}

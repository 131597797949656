.footer {
  margin-top: auto !important;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-top: 2px solid #dee2e6;
  p {
    padding: 0;
    margin: 0;
    a {
      color: #000;
      font-size: 12px !important;
      font-weight: 500;
      line-height: 20px;
    }
    span {
      font-size: 12px !important;
    }
  }
  a.nav-link {
    font-size: 12px !important;
    color: #2d2d2d;
  }
  a.nav-link:hover {
    text-decoration: underline;
  }
  a.nav-link:last-child {
    padding-right: 0;
  }
}

:root {
  --wgtWhite: #fff;
  --wgtGray: #cccfd2;
  --wgtDarkGray: #92999c;
  --wgtLightGray: #f3f3f3;
  --wgtLighterGray: #fafafa;
  --wgtRed: #d31a1a;
  --wgtTextColor: #373c3e;

  --primaryColor: #014a6a;
  --linkColor: #1e87f6;
  --mutedColor: #929a9c;

  --primaryButton: #8aca26;
  --disabledButton: #9fa6a9;
  --switchButtonSelected: #0074a0;
  --wgtPrimary: #0074a0;

  --switchButtonSwitcherBg: #4b5052;

  --wgtInputText: #5d6469;
  --wgtInputBorder: #bdbdbd;

  --wgtBoxShadow: 0 2px 5px 2px rgba(0, 0, 1, 0.164) !important;

  --break-small: 640px;
  --break-large: 1200px;

  --wgt-orange: #e17b22;
}

/* Colors */
$gray-01: #92999c;
$gray-02: #dbdbdb; // borders
$gray-03: #f9f9f9; // input disabled, background pageid select and background Page ID header
$gray-04: #727a7d; // label input, text select parcel and text header reports and calculations
$gray-05: #373c3e; // text input and text badge and currency in total input
$gray-06: #717171; // header common attributes
$gray-07: #e4e8ef; // border badge and line and border Page ID header
$gray-08: #929a9c; // placeholder input and border add page id
$gray-09: #d8d7d7; // header totals and borders header reports and calculations
$gray-10: #dedede; // background header reports and calculations
$gray-11: #a5a5a5;

$blue-01: #014a6a;
$blue-02: #0074a0;
$blue-03: #488bb1;
$blue-04: #1e87f6;

$orange-1: #e17b22;

$white-01: #ffffff;

@mixin title {
  font-family: 'Noto Serif' !important;
  color: var(--primaryColor) !important;
  font-weight: 400 !important;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 36px !important;
  line-height: 49px !important;
  @include title;
}

h2 {
  font-size: 28px !important;
  line-height: 38px !important;
  @include title;
}

h3 {
  font-size: 20px !important;
  line-height: 27px !important;
  @include title;
}

h4 {
  font-size: 18px !important;
  line-height: 24px !important;
  @include title;
}

h5 {
  font-size: 16px !important;
  line-height: 1.25 !important;
  @include title;
}

p,
span {
  font-family: 'noto_sansregular' !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

@import '../../../../assets/scss/variables';

@mixin productBorderBottom {
  border-bottom: 2px solid #e4e8ef;
}

.update-product-screen {
  .sku-form {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 5px;
    font-size: 12px;

    .sku-form {
      border: none !important;
    }

    .search-form-sku > div > div > div[id^='react-select-'] {
      color: #9b9999 !important;
      font-style: normal;
    }

    .search-form-sku > div > div > div[class$='-placeholder'] {
      color: #ccc !important;
      font-style: italic;
    }

    .search-form-sku > div > div > div[class$='-singleValue'] {
      color: #9b9999 !important;
      font-style: normal;
    }

    label {
      margin: 0;
      padding: 0;
    }

    input {
      border: 1px solid #bdbdbd;
      background: none;
      width: 200px;
      padding: 5px;
      border-radius: 5px;

      &::placeholder {
        font-style: italic;
        color: #9b9999;
      }
    }
  }

  .common-attributes {
    @include productBorderBottom;

    .row-form {
      border-radius: 10px;
      border: 1px solid $gray-09;
      align-items: flex-start;
      justify-content: space-between;
      overflow: hidden;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .col {
        margin-top: -5px;

        input {
          margin: 10px;
          width: 65%;
        }

        label {
          background-color: #717171;
          width: 100%;
          margin: 0;
          padding: 10px;
          color: #fff;
          padding-top: 15px;
        }
      }
    }

    .add-crate-box-row {
      align-items: center;
      margin-top: 10px;

      .spacer {
        margin-right: 60px;
      }

      .add-button-holder {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
        color: #92999c;
        font-size: 12px;
      }
    }

    .box-id-btn-wrap,
    .crate-id-btn-wrap {
      position: absolute;
      right: 3rem;
      bottom: -2.5rem;

      label {
        color: $gray-01;
        font-size: 12px;
      }
    }

    .card-group .card-header {
      font-size: 12px;
      background-color: $gray-06;
      color: $white-01;
      border-bottom: 1px solid $gray-09;
    }

    .textarea-description {
      height: 8.7rem;
    }
  }

  .reports-content {
    width: 100%;

    .reports-content-form {
      border: 1px solid $gray-09;

      input {
        font-family: 'noto_sansregular' !important;
        height: 36px;
        border: 1px solid $gray-09;
        color: $gray-05;
        font-size: 14px;
        &::placeholder {
          font-style: italic;
          color: $gray-08;
        }
      }
    }

    h4 {
      @include productBorderBottom;
      padding-bottom: 0.5rem;
    }
    .reports-content-list {
      display: flex;
      flex-direction: row;

      table {
        font-size: 13px;
        border-radius: 10px;
        overflow: hidden;
        border-collapse: separate;
        border: 1px solid $gray-09;
        thead {
          background-color: $gray-10;
          th {
            color: $gray-04;
          }
        }
      }

      th,
      td {
        padding: 10px;
        vertical-align: middle;
        font-weight: normal;
        label {
          margin-bottom: 0;
          color: $gray-05;
        }
        button.button {
          height: 36px;
        }
        .download-btn {
          background-color: white;
          border: 1px solid $gray-01;
          padding: 6px 10px;
          border-radius: 5px;
          color: $gray-01;
          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .form-create-report {
      margin-top: 5px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      border: 1px solid #d8d7d7;
      padding: 10px;
      form {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;

        input {
          &::placeholder {
            font-style: italic;
            font-size: 11px;
          }
        }

        .row {
          width: 100%;
        }
      }
    }
  }
  .characteristic-content {
    width: 100%;
    .nav-tabs {
      border: 0;

      .nav-item {
        a {
          border: 1px solid var(--switchButtonSelected);
          color: var(--switchButtonSelected);
          margin: 0 7px;
          border-radius: 10px;
          padding: 5px 15px;
          vertical-align: middle;
          text-decoration: none;
          align-items: center;
          display: flex;

          &.active {
            background-color: var(--switchButtonSelected);
            color: #fff;
          }
        }
      }
    }
    .tab-content {
      .tab-pane {
        div {
          flex-direction: row;
          display: flex;
          margin-top: 20px;
          span {
            cursor: pointer;
            border: 1px solid var(--wgtGray);
            color: var(--wgtTextColor);
            margin: 0 7px;
            border-radius: 10px;
            padding: 5px 15px;
            vertical-align: middle;
            text-decoration: none;
            align-items: center;
            display: flex;

            &.active {
              background-color: var(--switchButtonSelected);
              color: #fff;
            }
          }
        }
      }
    }
  }
  .product-advanced-content {
    .shape-and-price {
      .no-shape {
        p {
          font-size: 14px !important;
        }
      }

      .price-holder {
        margin-bottom: 1rem;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .fields-inline {
    margin-bottom: 1rem;
    .simple-input.template-input-component {
      display: flex !important;
      align-items: flex-start !important;
      flex-direction: column !important;
      margin-bottom: 0;
      label {
        text-align: left !important;
        width: 100%;
      }
    }
  }
}

@import "../../assets/scss/variables";

.wgt-check-button {
  display: flex;

  input[type="radio"] {
    display: none;
  }

  .field-label {
    width: 6.5rem;
    height: 27px;
    border: 1px solid $blue-02;
    background-color: $blue-02;
    border-radius: 0.4rem;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 0.5rem;
    p {
      color: $white-01;
      font-size: 13px !important;
      margin-bottom: 0;
      text-align: center;
    }

    svg {
      width: 100%;
      height: auto;
    }

    text {
      transform: translate(20px, 202px);
      font-size: 100px;
      fill: #000;
    }
  }

  .label-options {
    margin-bottom: 0.5rem;

    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    .form-check-inline {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem !important;

      &:hover {
        border-radius: 0.4rem;
        background-color: $gray-07;
      }

      label.form-check-label {
        cursor: pointer;
        border: 1px solid $gray-07;
        color: $gray-05;
        border-radius: 0.4rem;
        padding: 3px 15px;
        vertical-align: middle;
        text-decoration: none;
        font-size: 13px;
        min-width: 6.5rem;
        text-align: center;

        &.disabled {
          cursor: not-allowed;
        }

        &.selected {
          background-color: $gray-07;
          color: $gray-05;
        }

        &.form-check-image {
          width: 100px;
          height: 100px;
          background-size: cover;
          background-position: center;
          opacity: 1;
          &.selected {
            opacity: 0.6;
            border-width: 2px;
            border-color: #0074a0;
          }
        }
      }
    }

    .image-holder {
      text-align: center;

      span {
        font-size: 12px !important;
        margin-top: 10px;
      }
    }
  }
  &.invalid {
    label {
      border: 1px solid var(--wgtRed) !important;
    }
  }
}

.simple-dropdown {
  label {
    margin-left: 10px;
    font-size: 14px;
  }

  select {
    height: 36px;
    font-size: 14px;
    line-height: 1;
  }
}

.button-selector {
  background-color: var(--switchButtonSwitcherBg) !important;
  border-radius: 20px;
  display: inline-block;
  padding: 5px 0px;
  button {
    background-image: none !important;
    background-color: var(--switchButtonSwitcherBg) !important;
    color: var(--white) !important;
    margin: 0 7px;
    border-radius: 15px;
    padding: 0 30px;
    &.active {
      background-color: var(--switchButtonSelected) !important;
    }
  }
}

@import '../../assets/scss/scrollbar';

.schedule-modal {
  .modal-header {
    background-color: #e5e5e5;

    .modal-title {
      font-weight: bold !important;
      color: var(--wgtTextColor) !important;
      font-family: 'noto_sansregular' !important;
      font-size: 18px !important;
      line-height: 1 !important;
      margin-top: 3px;
    }
  }

  div.modal-dialog {
    .modal-content {
      .modal-body {
        .left-pane {
          width: 340px;
        }
        .open-margins {
          padding: 5px 15px;
          height: 41px;
          background-color: #f9f9f9;
          .open-margins-title {
            color: var(--wgtPrimary);
            white-space: nowrap;
            margin-right: 1rem;
          }
        }

        .row-item {
          box-sizing: border-box;
          border-radius: 4px;
          margin-top: 2px;

          .work-order-item-container {
            background: #f7fafd;
            border: 1px solid #1e87f6;
            box-sizing: border-box;
            border-radius: 4px;

            .text-sm {
              font-size: 10px !important;
            }
          }
        }

        .time-input {
          height: 36px;
        }

        .urgency-selector__control {
          height: 36px !important;
          min-height: 36px !important;

          .urgency-selector__single-value {
            font-size: 12px !important;
          }
          .urgency-selector__placeholder ~ div {
            padding-bottom: 4px;
            margin: 0;
            padding-top: 0;
          }

          .urgency-selector__clear-indicator,
          .urgency-selector__dropdown-indicator {
            padding: 1px !important;
          }

          .urgency-selector__indicator-separator {
            margin-bottom: 3px;
            margin-top: 3px;
          }

          .urgency-selector__input {
            input {
              margin-bottom: 2px;
            }
          }

          .urgency-selector__value-container {
            height: 35px !important;
            min-height: 35px !important;
            position: relative !important;
          }
        }

        @include scrollbar;
        .notes-scroll {
          height: 300px;
          overflow-y: auto;
        }
      }
      .modal-footer {
        display: flex;
        flex-wrap: unset;
        align-items: center;
        justify-content: unset;
        padding-left: 0;
        padding-right: 0;
        > * {
          margin: 0 !important;
        }
        > div {
          width: 100%;
        }

        .label-password {
          display: inline-block;
          font-size: 11px;
          width: 102px;
          margin-bottom: 0;
        }
      }
    }
  }

  label {
    font-size: 0.8rem;
  }
}

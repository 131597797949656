.side-sheet {
  padding: 10px;
  color: #0f56b3;
  font-size: 12px;
  font-weight: bold;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .close-sidebar {
    width: 100%;
    text-align: right;
    font-size: 14px;
    color: #1daeec;
    font-weight: 400;
    border-bottom: 2px solid #cccbcb;
    padding-bottom: 10px;
    cursor: pointer;
    margin: 20px 0;
  }

  .sidebar-pane-item {
    cursor: pointer;
    width: 100%;
    background-color: #e0e0e0;
    padding: 0 10px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    a {
      font-size: 12px !important;
      color: #0f56b3;
      text-decoration: none;
      height: 100%;
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 15px;
    }
  }
}

.sidebar-mega-menu {
  padding: 0.5rem 0 0.5rem 0;
  justify-content: unset;
  align-items: unset;

  .btn-sign-out {
    text-align: right;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    button {
      background: unset;
      border: unset;
      color: #0f56b3;
      font-size: 16px;
      font-family: 'noto_sansbold';
    }
  }

  .close-sidebar {
    width: 80% !important;
    margin: 0 auto !important;
    margin-bottom: 2rem !important;
    margin-top: 1rem !important;
    padding-bottom: 1rem !important;

    .icon-close {
      font-size: 16px;
      svg {
        border: 1px solid #1daeec !important;
        padding: 0.25rem;
        height: 32px;
        width: 32px;
        border-radius: 0.25rem;
        margin-left: 0.25rem;
        margin-bottom: 0.15rem;
      }
    }
  }

  .wrap-pane-item {
    &.center {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &.right {
      padding-right: 1rem !important;
      display: flex !important;
      justify-content: flex-end !important;
    }
    .sidebar-pane-item {
      margin-bottom: 1rem;
      padding: 0 1rem;
      height: 3.25rem;
      font-size: 14px !important;
      transition: 200ms;
      &:hover {
        opacity: 0.8;
      }
      a {
        font-size: 14px !important;
      }
      &.center {
        border-radius: 10px !important;
      }
      &.right {
        border-radius: 10px 0 0 10px;
        width: 86%;
        padding-left: 2.5rem !important;
      }
      &.left {
        padding-left: 2rem !important;
        padding-right: 1.5rem !important;
        border-radius: 0 10px 10px 0 !important;
        width: auto;
        display: inline-flex;
      }
      svg {
        margin-right: 15px;
        height: 18px;
        width: 18px;
      }
    }
  }
}

div[evergreen-portal-container] > div > div {
  z-index: 1051;
  > div > div:first-child {
    display: none;
  }
}

.navbar-expandable-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;

  p {
    color: #929a9c;
    font-size: 12px;
    font-weight: normal;
  }

  .navbar-expandable-container-item {
    width: 100%;
    margin-bottom: 20px;

    button,
    a {
      font-weight: 700;
      font-size: 14px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: none;
      color: #000;
    }
    .child-link {
      font-weight: normal;
      font-size: 14px;
      color: #273037;
      margin-left: 50px;
    }
  }
}

.breadcrumb-component {
  background-color: #e5e5e5;
  padding: 15px 0;

  a {
    color: #92999c;
    text-transform: capitalize;
    font-family: 'Noto Serif' !important;

    &.active {
      color: #373c3e;
    }
  }
  .breadcrumb-component-icon {
    color: #92999c;
    font-size: 10px;
    margin: 0 5px;
  }

  .bc-search {
    background-color: #fff;
    border: 1px solid #9b9999;
    padding: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 5px;
    font-size: 12px;

    input {
      border: 0;
      background: none;
      width: 150px;

      &::placeholder {
        font-style: italic;
        color: #9b9999;
      }
    }
  }
}

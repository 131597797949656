.product-card-component {
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-shadow: var(--wgtBoxShadow);
  padding: 10px 0;
  border-radius: 5px;
  align-items: center;

  &.selected {
    border: 2px solid var(--primaryButton);
    background-color: #f8f8f8;
  }
  .product-card-component-content {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    justify-content: space-around;
  }

  .product-card-component-avatar {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;

    img {
      height: 70px;
    }
  }
  .product-card-component-address {
    span {
      line-height: 15px;
    }
  }
  span {
    font-size: 11px !important;
    display: block;
    color: var(--wgtTextColor) !important;

    b {
      font-family: 'noto_sansbold' !important;
      color: var(--wgtTextColor) !important;
    }
  }
  .product-card-component-icon {
    color: var(--wgtTextColor);
    cursor: pointer;
  }
}
